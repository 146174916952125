import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TextArea } from "@progress/kendo-react-inputs";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import {
  valueToJs,
  convertMstoSec,
  convertToPercentage,
  convertToThousand,
  valueToKgs,
  valueToWh,
  bytestoMB,
} from "./Conversions";
import { saveAs } from "file-saver";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function PowerUsage({ toggleShowLogs, toggleShowGraphs }) {
  const { RunId } = useContext(RunSummaryContextNV);

  let cycle0 = null;
  let cycle1 = null;
  let cycle2 = null;

  const logsHeaderRowHeight = 30;

  const [Loading, setLoading] = useState(true);
  const initialDataState = {};
  const [showPWDiskData, setshowPWDiskData] = useState(false);
  const [dataState, setDataState] = React.useState();
  const [pwLogs, setPwLogs] = useState([]);
  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {showPWDiskData ? (
              <p className="tilefontsize">
                {valueToJs(
                  showPWDiskData.EnergyUsage.TotalEnergyUsageMillijoule,
                  0
                )}
              </p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">ENERGY USAGE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Download:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.EnergyUsage.DownloadPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Install:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.EnergyUsage.InstallPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Reboot:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.EnergyUsage.RebootPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {showPWDiskData ? (
              <p className="tilefontsize">
                {" "}
                {valueToKgs(
                  showPWDiskData.CarbonFootprint.CarbonFootprintGramsCO2e
                )}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">CARBON FOOTPRINT</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              CPU:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.CarbonFootprint.CpuPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle">--</span>
              )}
            </p>

            <p className="tilesubheaderstyle" tabIndex={0}>
              Disk:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.CarbonFootprint.DiskPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Network:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {showPWDiskData.CarbonFootprint.NetworkPercentage}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {showPWDiskData ? (
              <p className="tilefontsize">
                {valueToWh(showPWDiskData.BatteryLife.BatteryLifeWh)}
              </p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">BATTERY LIFE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Surface Book:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {
                    showPWDiskData.BatteryLife.BatteryDrainPercentage[
                      "Surface Book"
                    ]
                  }
                  %
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Surface Pro:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {
                    showPWDiskData.BatteryLife.BatteryDrainPercentage[
                      "Surface Pro"
                    ]
                  }
                  %
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Surface Laptop 7 15”:
              {showPWDiskData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {
                    showPWDiskData.BatteryLife.BatteryDrainPercentage[
                      "Surface Laptop 7 15”"
                    ]
                  }
                  %
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  useEffect(() => {
    const fetchPowerUsageDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetPowerUsageDetails(RunId);
        if (Object.keys(res.data).length) {
          setLoading(false);
          setshowPWDiskData(res.data);
          setPwLogs(res.data.Logs);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchPowerUsageDetails();
  }, [RunId]);

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Power Usage{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      {showPWDiskData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight="auto"
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={diskTiles}
              columnWidth={476}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default PowerUsage;
