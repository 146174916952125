import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";

function ValidationTile(props) {
  const {
    ValidationDetailsData,
    setValidationDetailsData,
    InitialLoading,
    setInitialLoading,
    RunId,
    setRunId,
  } = useContext(RunSummaryContextNV);

  const [Loading, setLoading] = useState(true);
  const initialDataState = {};
  const [showValidationDetails, setShowValidationDetails] = useState(false);
  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React.useState(
    process(ValidationDetailsData, initialDataState)
  );

  const dateChangeConversion = (props) => {
    let dateTimeValue = DateTime.fromISO(props.dataItem.ValidationTime);
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };
    let dateTime = dateTimeValue.toLocaleString(options);
    return <td className="alignValidator">{dateTime}</td>;
  };
  useEffect(() => {
    setResultState(process(ValidationDetailsData, initialDataState));
  }, [ValidationDetailsData]);
  useEffect(() => {
    const fetchValidationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetValidationDetails(RunId);
        setLoading(false);
        if (res.data.length > 0) {
          setValidationDetailsData((prev) => [...res.data]);
          setShowValidationDetails(true);
          setInitialLoading(false);
        }
        if (res.data.length === 0) {
          setShowValidationDetails(false);
          setInitialLoading(false);
        }
      } catch (er) {
        setLoading(false);
      }
    };
    fetchValidationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);

  return (
    <React.Fragment>
      {showValidationDetails && (
        <PanelBar style={{ margin: "20px" }}>
          <PanelBarItem expanded={true} title="Validation Details">
            <div>
              {InitialLoading ? (
                <Loader
                  size={"large"}
                  type={"converging-spinner"}
                  style={{ alignSelf: "center" }}
                />
              ) : (
                <React.Fragment>
                  <style>
                    {`col:nth-of-type(1){
                      width: 15%
                  }
                   col:nth-of-type(2){
                      width: 16%
                  }
                  col:nth-of-type(3){
                    width: 10%
                }
                   col:nth-of-type(4){
                  width: 64%
                    }`}
                  </style>
                  <Tooltip anchorElement="target" position="top">
                    <div id="Dashboard" className="dashboard-page main-content">
                      <div className="card-container grid">
                        <div className="card-component">
                          {ValidationDetailsData.length > -1 ? (
                            <Grid
                              data={{ data: resultState.data }}
                              sortable={true}
                            >
                              <GridColumn
                                field="ValidatorName"
                                title="Validator"
                                className="alignValidator"
                              />
                              <GridColumn
                                field="ValidationTime"
                                title="Validation Time"
                                className="alignValidator"
                                cell={dateChangeConversion}
                              />
                              <GridColumn
                                field="Result"
                                title="Result"
                                className="alignValidator"
                              />
                              <GridColumn
                                field="Details"
                                title="Details"
                                className="alignValidator"
                              />
                            </Grid>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </React.Fragment>
              )}
              <div className="Loading">
                {Loading && InitialLoading === false && (
                  <Loader
                    size={"large"}
                    type={"converging-spinner"}
                    style={{ position: "absolute", left: "42%", top: "32%" }}
                  />
                )}
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default ValidationTile;
