import { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DefaultFilter } from "../Constants/DefaultValue";
import { RunSummaryOptions } from "../Constants/RunSummaryOptions";

const RunSummaryContextNV = createContext();

const RunSummaryContextProvider = (props) => {
  const [RunNumber, setRunNumber] = useState();
  const [Milestone, setMilestone] = useState("");
  const [Notes, setNotes] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [MachineType, setMachineType] = useState("");
  const [RunStatus, setRunStatus] = useState("");
  const [BaselineType, setBaselineType] = useState("");
  const [Product, setProduct] = useState("");
  const [BaselineBuildRevision, setBaselineBuildRevision] = useState("");
  const [TargetBuildRevision, setTargetBuildRevision] = useState("");
  const [Sku, setSku] = useState("");
  const [DiskType, setDiskType] = useState("");
  const [CoreCount, setCoreCount] = useState("");
  const [RamSize, setRamSize] = useState("");
  const [IsExperiment, setIsExperiment] = useState("");

  const [Initial, setInitial] = useState(0);
  const [ShowExceptionPopup, setShowExceptionPopup] = useState(false);
  const [CorrelationId, setCorrelationId] = useState("");
  const FilterValues = RunSummaryOptions();
  var history = useHistory();
  const Param = new URLSearchParams(history.location.search.substring(1));
  const [RunId, setRunId] = useState(Param.get("RunId"));
  const [InitialLoadingTable, setInitialLoadingTable] = useState(true);
  const [ExSumSearch, setExSumSearch] = useState(
    history.location.state ? history.location.state.ExSumSearch : DefaultFilter
  );
  const [ValidationDetailsData, setValidationDetailsData] = useState([]);
  const [diskIodata, setdiskIodata] = useState(true);
  const [diskIoCount, setdiskIoCount] = useState();
  const [diskReadIo, setdiskReadIo] = useState();
  const [diskWriteIo, setdiskWriteIo] = useState();
  const [diskTransferSize, setdiskTransferSize] = useState();
  const [diskReadSize, setdiskReadSize] = useState();
  const [diskWriteSize, setdiskWriteSize] = useState();
  const [diskIoTime, setdiskIoTime] = useState();
  const [diskIoPerSecond, setdiskIoPerSecond] = useState();
  const [diskServiceTime, setdiskServiceTime] = useState();
  const [disklogs, setdiskLogs] = useState([]);
  const [cpulogs, setCpuLogs] = useState([]);
  const [TotalTime, setTotalTime] = useState();
  const [OnlineUsage, setOnlineUsage] = useState();
  const [ShutdownUsage, setShutdownUsage] = useState();
  const [BootUsage, setBootUsage] = useState();
  const [TotalLoad, setTotalLoad] = useState();
  const [System, setSystem] = useState();
  const [Defender, setDefender] = useState();
  const [Other, setOther] = useState();
  const [IdleTimePercent, setIdleTimePercent] = useState();
  const [TotalThreads, setTotalThreads] = useState();
  const [WaitTime, setWaitTime] = useState();
  const [ReadyTime, setReadyTime] = useState();
  const [systemUsage, setSystemUsage] = useState();
  const [defenderUsage, setDefenderUsage] = useState();
  const [otherUsage, setOtherUsage] = useState();
  const [ScanTimeMilliseconds, setScanTimeMilliseconds] = useState();
  const [scanOffered, setscanOffered] = useState();
  const [DownloadTimeMilliseconds, setDownloadTimeMilliseconds] = useState();
  const [DownloadErrorCount, setDownloadErrorCount] = useState();
  const [InstallTimeMilliseconds, setInstallTimeMilliseconds] = useState();
  const [InstallErrorCount, setInstallErrorCount] = useState();
  const [RebootTimeMilliseconds, setRebootTimeMilliseconds] = useState();
  const [RebootCount, setRebootCount] = useState();
  const [ExData, setExData] = useState(
    history.location.state ? history.location.state.ExData : []
  );
  const [CpuUsageData, setCpuUsageData] = useState(true);
  const [diskTransferdata, setdiskTransferdata] = useState(true);
  const [diskPerformancedata, setdiskPerformancedata] = useState(true);
  const [DiskLogsData, setDiskLogsData] = useState(true);
  const [CpuUtilizationdata, setCpuUtilizationdata] = useState(true);
  const [CpuPerformancedata, setCpuPerformancedata] = useState(true);
  const [cpuLogsdata, setcpuLogsdata] = useState(true);
  const [ScanGroupData, setScanGroupData] = useState(true);
  const [DownloadGroupData, setDownloadGroupData] = useState(true);
  const [InstallGroupData, setInstallGroupData] = useState(true);
  const [RebootGroupData, setRebootGroupData] = useState(true);
  const [updatelogs, setupdatelogs] = useState([]);
  const [UpdateLogsData, setUpdateLogsData] = useState(true);
  const [downloadSizeData, setdownloadSizeData] = useState(true);
  const [metaData, setmetaData] = useState(true);
  const [lcu, setlcu] = useState(true);
  const [store, setstore] = useState(true);
  const [otherdownload, setotherdownload] = useState(true);
  const [wupercent, setwupercent] = useState(true);
  const [bandwidthUsage, setbandwidthUsage] = useState(true);
  const [storePercent, setstorePercent] = useState(true);
  const [otherBandwidthPercent, setotherBandwidthPercent] = useState(true);
  const [trafficProperties, settrafficProperties] = useState(true);
  const [lcuDownloadSeconds, setlcuDownloadSeconds] = useState(true);
  const [lcuAverageDownloadSpeed, setlcuAverageDownloadSpeed] = useState(true);
  const [downloadPhaseIdleTime, setdownloadPhaseIdleTime] = useState(true);
  const [NetworkLogsData, setNetworkLogsData] = useState(true);
  const [networkLogs, setnetworkLogs] = useState([]);
  const [networkData, setnetworkData] = useState(true);
  const [diskspaceData, setDiskspaceData] = useState(true);
  const [fileCountData, setFileCountData] = useState(true);
  const [fileAccessData, setFileAccessData] = useState(true);
  const [diskspaceLogsData, setDiskspaceLogsData] = useState(true);
  const [multipleRunData, setMultipleRunData] = useState({});
  const [DiskV2LogsData, setDiskV2LogsData] = useState(true);
  const [diskIodataV2, setdiskIodataV2] = useState(true);
  const [diskUDataV2, setdiskUDataV2] = useState(null);
  const [diskV2logs, setdiskV2Logs] = useState([]);
  const value = {
    RunId,
    setRunId,
    RunNumber,
    setRunNumber,
    Milestone,
    setMilestone,
    Notes,
    setNotes,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    MachineType,
    setMachineType,
    RunStatus,
    setRunStatus,
    BaselineType,
    setBaselineType,
    Product,
    setProduct,
    BaselineBuildRevision,
    setBaselineBuildRevision,
    TargetBuildRevision,
    setTargetBuildRevision,
    Sku,
    setSku,
    DiskType,
    setDiskType,
    CoreCount,
    setCoreCount,
    RamSize,
    setRamSize,
    IsExperiment,
    setIsExperiment,
    InitialLoadingTable,
    setInitialLoadingTable,
    Initial,
    setInitial,
    ExSumSearch,
    setExSumSearch,
    ShowExceptionPopup,
    setShowExceptionPopup,
    CorrelationId,
    setCorrelationId,
    ExData,
    setExData,
    FilterValues,
    ValidationDetailsData,
    setValidationDetailsData,
    diskIodata,
    setdiskIodata,
    diskIoCount,
    setdiskIoCount,
    diskReadIo,
    setdiskReadIo,
    diskWriteIo,
    setdiskWriteIo,
    diskPerformancedata,
    setdiskPerformancedata,
    diskTransferSize,
    setdiskTransferSize,
    diskReadSize,
    setdiskReadSize,
    diskWriteSize,
    setdiskWriteSize,
    diskIoTime,
    setdiskIoTime,
    diskIoPerSecond,
    setdiskIoPerSecond,
    diskServiceTime,
    setdiskServiceTime,
    disklogs,
    setdiskLogs,
    TotalTime,
    setTotalTime,
    OnlineUsage,
    setOnlineUsage,
    ShutdownUsage,
    setShutdownUsage,
    BootUsage,
    setBootUsage,
    TotalLoad,
    setTotalLoad,
    System,
    setSystem,
    Defender,
    setDefender,
    Other,
    setOther,
    IdleTimePercent,
    setIdleTimePercent,
    TotalThreads,
    setTotalThreads,
    WaitTime,
    setWaitTime,
    ReadyTime,
    setReadyTime,
    systemUsage,
    setSystemUsage,
    defenderUsage,
    setDefenderUsage,
    otherUsage,
    setOtherUsage,
    ScanTimeMilliseconds,
    setScanTimeMilliseconds,
    scanOffered,
    setscanOffered,
    DownloadTimeMilliseconds,
    setDownloadTimeMilliseconds,
    DownloadErrorCount,
    setDownloadErrorCount,
    InstallTimeMilliseconds,
    setInstallTimeMilliseconds,
    InstallErrorCount,
    setInstallErrorCount,
    RebootTimeMilliseconds,
    setRebootTimeMilliseconds,
    RebootCount,
    setRebootCount,
    cpulogs,
    setCpuLogs,
    CpuUsageData,
    setCpuUsageData,
    diskTransferdata,
    setdiskTransferdata,
    DiskLogsData,
    setDiskLogsData,
    CpuUtilizationdata,
    setCpuUtilizationdata,
    CpuPerformancedata,
    setCpuPerformancedata,
    cpuLogsdata,
    setcpuLogsdata,
    ScanGroupData,
    setScanGroupData,
    DownloadGroupData,
    setDownloadGroupData,
    InstallGroupData,
    setInstallGroupData,
    RebootGroupData,
    setRebootGroupData,
    updatelogs,
    setupdatelogs,
    UpdateLogsData,
    setUpdateLogsData,
    downloadSizeData,
    setdownloadSizeData,
    metaData,
    setmetaData,
    lcu,
    setlcu,
    diskspaceData,
    setDiskspaceData,
    fileCountData,
    setFileCountData,
    fileAccessData,
    setFileAccessData,
    diskspaceLogsData,
    setDiskspaceLogsData,
    store,
    setstore,
    otherdownload,
    setotherdownload,
    bandwidthUsage,
    setbandwidthUsage,
    wupercent,
    setwupercent,
    storePercent,
    setstorePercent,
    otherBandwidthPercent,
    setotherBandwidthPercent,
    trafficProperties,
    settrafficProperties,
    lcuDownloadSeconds,
    setlcuDownloadSeconds,
    lcuAverageDownloadSpeed,
    setlcuAverageDownloadSpeed,
    downloadPhaseIdleTime,
    setdownloadPhaseIdleTime,
    NetworkLogsData,
    setNetworkLogsData,
    networkLogs,
    setnetworkLogs,
    networkData,
    setnetworkData,
    multipleRunData,
    setMultipleRunData,
    diskIodataV2,
    setdiskIodataV2,
    diskUDataV2,
    setdiskUDataV2,
    DiskV2LogsData,
    setDiskV2LogsData,
    diskV2logs,
    setdiskV2Logs,
  };

  return (
    <RunSummaryContextNV.Provider value={value}>
      {props.children}
    </RunSummaryContextNV.Provider>
  );
};

export { RunSummaryContextNV, RunSummaryContextProvider };
