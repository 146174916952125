import { DateTime } from "luxon";

export const getFormattedDate = (dateFormat) => {
  if (dateFormat === "N/A") return "";

  let dateValue = DateTime.fromISO(dateFormat);
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  return dateValue.toLocaleString(options);
};

export const getDateOnly = (dateFormat) => {
  return dateFormat.split("T")[0].replaceAll("-", "/");
};

export const runStatus = {
  Anomalous: "Anomalous",
  Success: "Success",
  Representative: "Representative",
};

export function elementTooltip(name, annotation) {
  return name + " (" + annotation + ")";
}
