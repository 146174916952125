/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import * as API from "../Services/ApiService/GetRuns";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FilterContext } from "../Contexts/FilterContext";
import { useHistory } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import Summary from "./Summary";
import "./../Styles/_executiveSummary.scss";
// import TilaIncidentSummary from "./TilaIncidentSummary";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { process } from "@progress/kendo-data-query";
import $ from "jquery";
import { getDateOnly } from "../Common/Utilities";

const ExecutiveSummary = (props) => {
  const {
    Body,
    Page_No,
    setPage_No,
    Data,
    setData,
    InitialLoading,
    setInitialLoading,
    ShowExceptionPopup,
    setShowExceptionPopup,
    CorrelationId,
    setCorrelationId,
    InsideIncidentUri,
  } = useContext(FilterContext);

  const initialColumns = [
    {
      field: "startDate",
      title: "Date",
    },
    {
      field: "milestone",
      title: "Milestone",
    },
    {
      field: "product",
      title: "Product",
    },
    {
      field: "configurationName",
      title: "Configuration",
    },
    {
      field: "numberOfRuns",
      title: "Runs",
    },
    {
      field: "runStatus",
      title: "Status",
    },
  ];

  const [perPage] = useState(100);
  const [Loading, setLoading] = useState(true);
  const [Total, setTotal] = useState(0);
  const [columns, setColumns] = React.useState(initialColumns);
  const [summaryData, setSummaryData] = useState({
    totalRecords: 0,
    runCompleted: 0,
    runInProgress: 0,
    runFailed: 0,
    validationFailed: 0,
  });

  const initialDataState = {};

  const [dataState, setDataState] = React.useState();

  const [resultState, setResultState] = React.useState(
    process(Data, initialDataState)
  );

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(Data, e.dataState));
    },
    [Data]
  );

  let grid;

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const res = await API.fetchMyAPI(Body, Page_No, perPage);
        setLoading(false);
        if (res.data) {
          res.data.entities.forEach((el, idx) => {
            el.startDate = getDateOnly(el.startDate);
          });
          setData((prev) => [...prev, ...res.data.entities]);
          setTotal(res.data.totalRecords);
          setInitialLoading(false);
          // setNoData(false);
          setSummaryData({
            totalRecords: res.data.totalRecords,
            runCompleted: res.data.runCompleted,
            runInProgress: res.data.runInProgress,
            runFailed: res.data.runFailed,
            validationFailed: res.data.validationFailed,
          });
        }
        if (res.data === "") {
          setInitialLoading(false);
          setSummaryData({
            totalRecords: 0,
            runCompleted: 0,
            runInProgress: 0,
            runFailed: 0,
            validationFailed: 0,
          });
        }
      } catch (er) {
        if (er.response && er.response.data && er.response.data.CorrelationId)
          setCorrelationId(er.response.data.CorrelationId);
        else setCorrelationId("Not defined");
        setInitialLoading(false);
        setLoading(false);
        setData(history.location.state.ExData);
        console.log("error", er);
        props.trackException(er);
      }
    };
    fetch();
    $(".k-grid-norecords").removeAttr("aria-rowindex");
  }, [Body, Page_No, perPage]);

  useEffect(() => {
    props.toggleFilterSection(false);
    setData([]);
    setPage_No(1);
    // setPrevNextData([]);
  }, []);

  useEffect(() => {
    grid = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
  }, []);

  const num = Math.floor(Total / perPage) + (Total % perPage ? 1 : 0);
  const handleScroll = (event) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (Page_No < num) {
        setPage_No((prevPage_No) => prevPage_No + 1);
      }
    }
  };

  const handleResize = () => {
    const currentVisibleColumns = columns.filter(
      (c) => !(c.minGridWidth > grid?.offsetWidth)
    );

    if (currentVisibleColumns.length !== columns.length) {
      setColumns(currentVisibleColumns);
    }
  };

  const history = useHistory();

  React.useEffect(() => {
    setResultState(process(Data, initialDataState));
    // Handle accessibility warnings in code
    setTimeout(() => {
      document.querySelectorAll("[role='presentation']").forEach((e) => {
        e.removeAttribute("role");
      });
      document.querySelectorAll(".k-grid").forEach((e) => {
        e.removeAttribute("role");
        e.removeAttribute("aria-colcount");
      });
    }, 1200);

    let ids = setTimeout(() => {
      document.querySelector('[id^="k-panelbar-item-default"]').forEach((e) => {
        console.log(e);
        e.removeAttribute("id");
      });
    }, 4000);
    // Handle accessibility warnings in code
  }, [Data]);

  const rowClick = (e) => {
    let url = `http://${window.location.host}/run?RunId=${e.dataItem.runId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResultState({ ...resultState });
  };

  $(".k-grid-norecords").removeAttr("aria-rowindex");
  return (
    <div className="executive-summary-container">
      {InitialLoading ? (
        <Loader
          size={"large"}
          type={"converging-spinner"}
          style={{ alignSelf: "center" }}
        />
      ) : (
        <React.Fragment>
          <style>
            {`
            col:nth-last-of-type(6){
              width: 7%
          }
                col:nth-last-of-type(5){/*milestone*/
                    width: 26% 
                }
                col:nth-last-of-type(4){/*product*/
                    width: 26% 
                }
                col:nth-last-of-type(3){ /*configuration*/
                width: 27% 
                }
                col:nth-last-of-type(2){ /*runs*/
                width: 4%
                }
                col:nth-last-of-type(1){ /*status*/
                  width: 10%
                  }
                `}
          </style>
          <Tooltip anchorElement="target" position="top">
            <div id="Dashboard" className="dashboard-page main-content">
              <div className="card-container grid">
                <div className="card-component">
                  {Data.length > -1 ? (
                    <Grid
                      onScroll={handleScroll}
                      onRowClick={rowClick}
                      style={{
                        height: "85vh",
                      }}
                      data={{ data: resultState.data }}
                      sortable={true}
                      groupable={true}
                      onDataStateChange={onDataStateChange}
                      {...dataState}
                      onExpandChange={expandChange}
                      expandField="expanded"
                    >
                      {columns.map((column, index) => {
                        return <Column {...column} key={index} />;
                      })}
                    </Grid>
                  ) : null}
                  <div style={{ padding: 0 }}>
                    <Summary summaryData={summaryData} />{" "}
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </React.Fragment>
      )}
      <div className="Loading">
        {Loading && InitialLoading === false && (
          <Loader
            size={"large"}
            type={"converging-spinner"}
            style={{ position: "absolute", left: "42%", top: "32%" }}
          />
        )}
      </div>
    </div>
  );
};

export default ExecutiveSummary;
