import { DefaultFilter } from "../GetDefaultFilter";
// import { DefaultValue } from "./DefaultValue";

export const FilterOptions = (ShowMore) => {
  var products = DefaultFilter("product", []);
  var release = DefaultFilter("release", []);
  var milestone = DefaultFilter("Milestone", []);
  var runStatus = DefaultFilter("RunStatus", []);
  var baselineType = DefaultFilter("BaselineType", []);
  var machineType = DefaultFilter("MachineType", []);
  var milestoneType = DefaultFilter("MilestoneType", []);

  return {
    Product: products,
    Release: release,
    Milestone: milestone,
    RunStatus: runStatus,
    BaselineType: baselineType,
    MachineType: machineType,
    MilestoneType: milestoneType
  };
};
