import React, { useState, useEffect, useContext } from "react";
import { elementTooltip } from "../Common/Utilities";
import { bytestoMB } from "./Conversions";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import * as API from "../Services/ApiService/GetRunDetails";
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";

function FlameGraphs(props) {
  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [check, setCheck] = React.useState([]);
  const [expand, setExpand] = React.useState({
    ids: ["Item2"],
    idField: "text",
  });
  const [select, setSelect] = React.useState([""]);
  const [errMessage, setErrMessage] = useState("");
  const onItemClick = (event) => {
    console.log(event);
  };
  const onExpandChange = (event) => {
    const ids = expand.ids ? expand.ids.slice() : [];
    const index = ids.indexOf(event.item.text);
    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({
      ids,
      idField: "text",
    });
  };
  const onCheckChange = (event) => {
    const settings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    setCheck(handleTreeViewCheckChange(event, check, graphs, settings));
  };

  const [graphs, setGraphs] = useState([]);
  const { RunId } = useContext(RunSummaryContextNV);

  useEffect(async () => {
    try {
      let tempArr = [];
      const res = await API.GetGraphLogs(RunId);
      Object.keys(res.data).forEach((key, idx, arr) => {
        tempArr.push({
          text: key,
          items: Object.entries(res.data)[idx][1].map((x) => {
            return {
              text: x.DisplayName,
              path: x.Path,
              sizeBytes: x.SizeBytes,
              extension: x.Extension,
              sasUri: x.SasUri,
              annotation: x.Annotation,
              name: x.Name,
            };
          }),
        });
      });

      setGraphs(tempArr);
    } catch (e) {
      setErrMessage("An error occurred.");
    }
  }, [RunId]);

  let currentCycle = -1;

  const itemTemplate = (props) => {
    if (props.item.sasUri) {
      return (
        <>
          <a
            onClick={() => handleDownload(props.item.sasUri)}
            data-toggle="tooltip"
          >
            <span
              data-toggle="tooltip"
              title={elementTooltip(props.item.name, props.item.annotation)}
            >
              {props.item.text}{" "}
            </span>
          </a>
          <span style={{ color: "black" }}>
            ({props.item.extension}, {bytestoMB(props.item.sizeBytes)})
          </span>
        </>
      );
    } else {
      return (
        <>
          <span data-toggle="tooltip">
            <span data-toggle="tooltip" title={props.item.text}>
              {props.item.text}
            </span>
          </span>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      {errMessage === "" && (
        <>
          <div>
            <p className="drawerheaderstyle">Flame Graphs</p>
          </div>
          {graphs.length > 0 && (
            <TreeView
              data={processTreeViewItems(graphs, {
                select: select,
                check: check,
                expand: expand,
              })}
              expandIcons={true}
              onExpandChange={onExpandChange}
              onItemClick={onItemClick}
              item={itemTemplate}
            />
          )}
          {graphs.length < 1 && <h4 style={{ color: "red" }}>No data.</h4>}
        </>
      )}
      {errMessage !== "" && (
        <>
          <p className="drawerheaderstyle">Flame Graphs</p>
          <div style={{ marginTop: "12px" }} id="menu">
            <h4 style={{ color: "red" }}>{errMessage}</h4>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
export default FlameGraphs;
