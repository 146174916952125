import React from "react";
import { Label } from "@progress/kendo-react-labels";

const Summary = (props) => {
  const summaryData = props.summaryData;
  return (
    <div data-testid="dummyTable" className="summary-container">
      <Label className="summary-item-label">
        Runs: {summaryData.totalRecords} |
      </Label>
      <Label className="summary-item-label">
        Completed: {summaryData.runCompleted} |
      </Label>
      <Label className="summary-item-label">
        In Progress: {summaryData.runInProgress} |
      </Label>
      <Label className="summary-item-label">
        Failed: {summaryData.runFailed} |
      </Label>
      <Label className="summary-item-label">
        Validations Failed: {summaryData.validationFailed}
      </Label>
    </div>
  );
};

export const MultiSummary = (props) => {
  const summaryData = props.summaryData;
  return (
    <div data-testid="dummyTable" className="summary-container">
      <Label className="summary-item-label">
        Runs: {summaryData.totalRecords} |
      </Label>
      <Label className="summary-item-label">
        Completed: {summaryData.runCompleted} |
      </Label>
      <Label className="summary-item-label">
        In Progress: {summaryData.runInProgress} |
      </Label>
      <Label className="summary-item-label">
        Failed: {summaryData.runFailed} |
      </Label>
      <Label className="summary-item-label">
        Anomalous: {summaryData.anomalousRuns}
      </Label>
    </div>
  );
};

export default Summary;
