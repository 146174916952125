import axios from "axios";
import { getMsalInstance } from "../../index";
import { globalConfig, globalConfigUrl } from "../../Configuration/config";

export async function getAccessTokenResponse(request) {
  let msalInstance = getMsalInstance();
  if (msalInstance !== undefined) {
    request.account = msalInstance.getActiveAccount();
    if (request.account != null) {
      //Get From Cache First
      return msalInstance.acquireTokenSilent(request).catch((error) => {
        console.error(error);
      });
    }
  }
}

function getApiToken(url){
  if(url.includes("trackit")){
    return globalConfig.config.wl_trackitApiToken;
  }
  return globalConfig.config.wl_defaultApiToken;
}

axios.interceptors.request.use(
  async (req) => {
    if (req.url !== globalConfigUrl) {
      var response = await getAccessTokenResponse(getApiToken(req.url));
      req.headers["Authorization"] =
        "Bearer " + (response !== undefined ? response.accessToken : "");
      req.headers["Access-Control-Allow-Methods"] =
        "GET,PUT,POST,DELETE,PATCH,OPTIONS";
      req.headers["Content-Type"] = "application/json;charset=UTF-8";
      // Add configurations here
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
