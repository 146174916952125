import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import { App } from "./Components/App";
import axios from "axios";
import { globalConfig, globalConfigUrl } from "./Configuration/config";
import "@progress/kendo-theme-material/dist/all.scss";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

let msalInstance = undefined;

axios
  .get(globalConfigUrl)
  .then(async (response) => {
    globalConfig.config = response.data;
    msalInstance = new PublicClientApplication(
      globalConfig.config.wl_msalConfig
    );

    // This is for logged in user
    const accounts = await msalInstance.getAllAccounts();
    if (accounts !== null && accounts.length > 0) {
      await msalInstance.setActiveAccount(accounts[0]);
    }

    // This is callback for use which is not logged in.
    await msalInstance.addEventCallback(async (event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        await msalInstance.setActiveAccount(account);
      }
    });

    return ReactDOM.render(
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>,

      document.getElementById("root")
    );
  })
  .catch((e) => {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error while fetching global config
      </p>
    );
  });

export function getMsalInstance() {
  return msalInstance;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
