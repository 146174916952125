/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ITool } from '../../Models/tool';
import '../../Styles/header.scss';
import '../../Styles/tool.scss';

import { openUrlInNewTab } from '../common/common';

export const ToolNameHeader: React.FC<ITool> = ({ item }) => {
    return (
        <div className="alignleft">
            <div className="shell-header-wrapper">
                <div className="shell-header-top">
                    <div className="shell-header-topfix">
                        <div className="shell-header-brand">
                            <div id="shell-header-text">
                                <a id="shell-header-logo"></a>
                                <a
                                    id="reportNameHeader"
                                    className="reportHeader"
                                    onClick={() => openUrlInNewTab(item.toolLink)}
                                    tabIndex={0}
                                >
                                    <span id="teamName">Windows Update Performance Lab</span>
                                </a>
                                <span id="toolName" className="reportHeader">
                                    {item.toolName}
                                </span>{' '}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
