export const DefaultValue = Object.freeze({
  All: { id: -1, name: "All" },
  FromDate: new Date(new Date().setYear(new Date().getFullYear() - 1)),
  ToDate: new Date(),
});
export const GetNone = (id) => {
  return { id: id, name: "", description: "" };
};
export const DefaultFilter =
  "Product%3D%7B%22id%22%3A0%2C%22name%22%3A%5B%22All%22%5D%7D%26%0ARelease%3D%7B%22id%22%3A1%2C%22name%22%3A%5B%22All%22%5D%7D%26%0AMilestone%3D%7B%22id%22%3A1%2C%22name%22%3A%5B%22All%22%5D%7D%26%0ARunStatus%3D%7B%22id%22%3A1%2C%22name%22%3A%5B%22All%22%5D%7D%26%0ABaselineType%7B%22id%22%3A1%2C%22name%22%3A%5B%22RTM%22%5D%7D%26%0AMachineType%7B%22id%22%3A1%2C%22name%22%3A%5B%22AzureVm%22%5D%7D%26%0ARunStartDate%7B%22id%22%3A1%2C%22name%22%3A%20%7B%20%22startDate%22%3A%222022-02-20%22%2C%20%22endDate%22%3A%222022-03-24%22%20%7D%7D";

// export const NotTr = "Not Triaged";
