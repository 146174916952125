export function openUrl(url: string, disabled: boolean) {
    if (disabled) {
        return false;
    }
    openUrlInNewTab(url);
}

export function openUrlInNewTab(url: string) {
    window.open(url, '_blank');
}
