import { DefaultFilter } from "../GetDefaultFilter";

export const RunSummaryOptions = () => {
  return {
    Product: DefaultFilter("Product", []),
    Release: DefaultFilter("Release", []),
    Milestone: DefaultFilter("Milestone", []),
    RunStatus: DefaultFilter("RunStatus", []),
    BaselineType: DefaultFilter("BaselineType", []),
    MachineType: DefaultFilter("MachineType", []),
  };
};
