import { IAppSwitcherItem } from '../../Models/appSwitcherItem';

export const AppSwitcherMenuItem: React.FC<IAppSwitcherItem> = ({ item }) => {
    return (
        <li className="appSwBtn">
            <a href={item.url} className="iconappSwBtn" target="_blank" rel="noreferrer">
                <img title={item.title} className="appSwitchIcon" src={item.iconSrc} alt="icon-app-switcher" />
                <span className="appSwLabel">{item.displayName}</span>
            </a>
        </li>
    );
};
