/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { IBanner } from '../../Models/banner';
import '../../Styles/header.scss';
import { AppSwitcher } from '../appSwitcher/appSwitcher';
import { FeedbackMenuItem } from '../feedback/feedbackMenuItem';
// import { LinkItem } from '../linkItems/linkItem';
// import { SearchTextBox } from '../search/searchTextBox';
// import { TextMenu } from '../TextBar/textMenu';
import { ToolNameHeader } from './toolNameHeader';
import { FeedbackSuccess } from '../feedback/feedbackSuccess';

export const Banner: React.FC<IBanner> = ({ item }) => {
    const [FeedbackMenuItemOpen, setFeedbackMenuItemOpen] = useState(false);
    const [AppSwitcherMenuItemOpen, setAppSwitcherMenuItemOpen] = useState(false);
    const [WorkItemId, setWorkItemId] = useState('');
    const [IcmIncidentId, setIcmIncidentId] = useState('');
    const [ShowFeedWindow, setShowFeedWindow] = useState(false);

    const handleFeedBackMenuOpen = () => {
        return FeedbackMenuItemOpen ? feedbackMenuClose() : feedbackMenuOpen();
    };

    const feedbackMenuOpen = () => {
        AppSwitcherMenuClose();
        setFeedbackMenuItemOpen(true);
        item.feedbackMenu.item.menuItemOpen = true;
    };

    const feedbackMenuClose = () => {
        setFeedbackMenuItemOpen(false);
        item.feedbackMenu.item.menuItemOpen = false;
    };

    const handleAppSwitcherMenuOpen = () => {
        return AppSwitcherMenuItemOpen ? AppSwitcherMenuClose() : AppSwitcherMenuOpen();
    };

    const AppSwitcherMenuOpen = () => {
        feedbackMenuClose();
        setAppSwitcherMenuItemOpen(true);
        item.appSwitcherMenu.menuOpen = true;
    };

    const AppSwitcherMenuClose = () => {
        setAppSwitcherMenuItemOpen(false);
        item.appSwitcherMenu.menuOpen = false;
    };

    const createWorkItemId = (value: any) => {
        setWorkItemId(value);
    };

    const createIcmIncidentId = (value: any) => {
        setIcmIncidentId(value);
    };

    const closeSuccessmessage = () => {
        setShowFeedWindow(false);
    };

    item.feedbackMenu.item.handleOpen = handleFeedBackMenuOpen;
    item.appSwitcherMenu.handleOpen = handleAppSwitcherMenuOpen;

    return (
        <>
            <table id="contentTable" className="contentsection">
                <tbody>
                    <tr className="headerblack full-screen-target">
                        <td>
                            <div id="summaryheader">
                                <ToolNameHeader item={item.tool} />
                                <div id="dvTopRightMenu" className="topRightMenu">
                                    {/* <TextMenu item={item.textItem.item} /> */}
                                    {/* <SearchTextBox /> */}
                                    {/* {item.links.items.map((menuItem, index) => {
                                        return <LinkItem key={index} item={menuItem.item} />;
                                    })} */}
                                    <FeedbackMenuItem
                                        item={item.feedbackMenu.item}
                                        createWorkItemId={createWorkItemId}
                                        createIcmIncidentId={createIcmIncidentId}
                                        setShowFeedWindow={setShowFeedWindow}
                                    />
                                    <AppSwitcher
                                        items={item.appSwitcherMenu.items}
                                        menuOpen={item.appSwitcherMenu.menuOpen}
                                        handleOpen={item.appSwitcherMenu.handleOpen}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {ShowFeedWindow && (
                <FeedbackSuccess
                    item={{
                        icmPortalLink: item.feedbackMenu.item.icmPortalLink,
                        workItemLink: item.feedbackMenu.item.workItemLink,
                        workItemId: WorkItemId,
                        icmIncidentId: IcmIncidentId,
                        closeSuccessmessage: closeSuccessmessage,
                    }}
                />
            )}
        </>
    );
};
