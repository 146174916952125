import { ClientSideError } from "../Constants/ErrorSeries";

export const HandleError = (
  er,
  setCorrelationId,
  setShowExceptionPopup,
  trackException,
  trackWarning,
  DisplayMessage,
  DisplayPopup
) => {
  if (er.response && er.response.data) {
    var status = er.response.status;
    var statusSeries = String(status)[0];
    if (statusSeries === ClientSideError) {
      trackWarning(er.response.data[""][0]);
      Handle400Error(er, DisplayMessage);
    } else {
      trackException(er);
      if (DisplayPopup)
        Handle500Error(er, setCorrelationId, setShowExceptionPopup);
      else DisplayMessage.func(DisplayMessage.message);
    }
  } else {
    trackException(er);
    if (DisplayPopup) {
      setCorrelationId("Not defined");
      setShowExceptionPopup(true);
    } else DisplayMessage.func(DisplayMessage.message);
  }
};

const Handle500Error = (er, setCorrelationId, setShowExceptionPopup) => {
  if (er.response.data.CorrelationId)
    setCorrelationId(er.response.data.CorrelationId);
  else setCorrelationId("Not defined");
  setShowExceptionPopup(true);
};

const Handle400Error = (er, DisplayMessage) => {
  DisplayMessage.func([er.response.data[""][0], "error"]);
};
