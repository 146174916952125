export const feedBackMessages = {
    PasteImageSupportedText: 'Press Ctrl + V to paste an image from your clipboard',
    PasteImageNotSupportedText: 'At this time pasting from the clipboard is not supported',
    TitleMissingAlertMessage: 'Please add a title.',
    DescriptionMissingTextMessage: 'Please add a description.',
    OnlyPngSupportedAlertText: 'At this time TrackIt only supports png files for upload.',
    ImageConversionError: 'An error occurred while converting this file.',
    FeedBackImageTitle: 'Submit feedback or report an incident',
};

export const feedBackDefaultValues = {
    TitleDefaultValue: '',
    DescriptionDefaultValue: '',
    PasteImageSupported: false,
};
