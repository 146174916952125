/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import {
  Notification,
  NotificationGroup  
} from "@progress/kendo-react-notification";

const position = {
  topLeft: {
    top: 0,
    left: 0,
    alignItems: "flex-start",
  },
  topCenter: {
    top: 0,
    left: "40%",
    transform: "translateX(-50%)",
  },
  topRight: {
    top: 0,
    right: 0,
    alignItems: "flex-end",
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
    alignItems: "flex-start",
  },
  bottomCenter: {
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  bottomRight: {
    bottom: 0,
    right: 0,
    alignItems: "flex-end",
  },
  middle:{
    
  }
};


const NotificationContext = createContext();

const NotificationContextProvider = (props) => {
  const [Message, setMessage] = useState(["", "info"]);
  const [ShowMessage, setShowMessage] = useState(true);

  const value = {
    setMessage,
    setShowMessage
  };

  useEffect(() => {
    setShowMessage(true)
    if (Message[1] !== "warning" && Message[0] !== "") {
      const timeId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [Message]);

  return (
    <NotificationContext.Provider value={value}>
      <NotificationGroup style={{ top:"6%", left:"60%", zIndex:"100000005"}}>
        {ShowMessage && Message[0] !== "" ? (
          <Notification
            type={{ style: Message[1], icon: true }}
            closable={true}
            onClose={() => setShowMessage(false)}
          >
            <span>{Message[0]}</span>
          </Notification>
          ) : (
            <div></div>
          )}
      </NotificationGroup>      
      {props.children}    
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationContextProvider };