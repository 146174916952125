/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import '../../Styles/header.scss';
import '../../Styles/appSwitcher.scss';
import { AppSwitcherMenu } from './appSwitcherMenu';
import { IAppSwitcherMenu } from '../../Models/appSwitcherItem';

export const AppSwitcher: React.FC<IAppSwitcherMenu> = ({ items, menuOpen, handleOpen }) => {
    return (
        <div className="menu-item-container" role="button" tabIndex={0}>
            <div className="dropbtn app-switcher menu-item-icon-wrapper topRightElements hoverbackground">
                <a id="appSwitcher" className="topRightElements hoverbackground" onClick={handleOpen} tabIndex={10}>
                    <img title="App Switcher Menu" className="iconsize" src="/ari_AppSwitch.png" alt="App-Switcher" />
                </a>
                {menuOpen && <AppSwitcherMenu items={items} menuOpen={menuOpen} handleOpen={handleOpen} />}
            </div>
        </div>
    );
};
