import React from "react";
// import {trackException, trackTrace, trackEvent} from "../Services/AppInsights/Track"
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // To log the error to an error reporting service
      this.props.trackException(error)
      // this.props.te("error")
      this.props.trackEvent("error boundary", {data: errorInfo})
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <div style={{position:"absolute", top:0, left:1,right:1, bottom:2, border:"2px double black"}}>
            <div style={{position :"absolute" ,top:"30%", width:"100vw"}}>
                <h1 style={{textAlign:"center"}}>Something went wrong.</h1> 
            </div>
        </div>);
      }
      return this.props.children; 
    }
  }
export default ErrorBoundary

