/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { TileLayout, Card, CardBody } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { getFormattedDate } from "../Common/Utilities";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import { truncValue } from "./Conversions";

function RunDetailsTile(props) {
  const { multipleRunData } = useContext(RunSummaryContextNV);
  const [currentRunIndex, setCurrentRunIndex] = useState(-1);

  const {
    RunNumber,
    Milestone,
    Notes,
    StartDate,
    EndDate,
    MachineType,
    RunStatus,
    BaselineType,
    Product,
    BaselineBuildRevision,
    TargetBuildRevision,
    Sku,
    DiskType,
    CoreCount,
    RamSize,
    IsExperiment,
    IsLoading,
    IsRepresentative,
    MachineName,
    PublicIp,
    DeviceId,
    DiskSize,
    toggleShowLogs,
  } = props;

  useEffect(() => {
    if (multipleRunData != undefined && multipleRunData.entities != undefined) {
      let idx = multipleRunData.entities.findIndex(
        (ent) => ent.runId == RunNumber
      );
      if (idx !== -1) setCurrentRunIndex(idx);
    } else {
      setCurrentRunIndex(0);
    }
  }, [multipleRunData]);

  document.title = `Run ${RunNumber} - ${Milestone}`;

  function RunIdStatusSection() {
    return IsLoading ? (
      <div></div>
    ) : (
      <div
        className={
          IsRepresentative
            ? "maxheight tis-idstatus-section-representative"
            : "maxheight tis-idstatus-section"
        }
        style={{
          borderLeftColor:
            RunStatus === "Run failed"
              ? "#ce8500" // added to address accessibility warning for color contrast
              : RunStatus === "Validation failed"
              ? "red"
              : "green",
        }}
      >
        <h3 className={"tis-idstatussev-header"}>
          <div
            className={"tis-margin-bottom-status-section"}
            style={{ fontSize: "18pt" }}
            tabIndex={0}
          >
            {RunNumber}
          </div>
          <div
            className={"tis-margin-middle-status-section"}
            style={{
              color:
                RunStatus === "Run failed"
                  ? "#ce8500" // added to address accessibility warning for color contrast
                  : RunStatus === "Validation failed"
                  ? "red"
                  : "green",
              fontSize: "18pt",
            }}
            tabIndex={0}
          >
            <b>{RunStatus === "" ? "N/A" : RunStatus}</b>
          </div>
          {IsRepresentative === true ? (
            <div style={{ fontSize: "18pt" }}>Representative</div>
          ) : (
            <div></div>
          )}
          <div className="open-logs-div">
            <span className="open-logs-label" onClick={toggleShowLogs}></span>
          </div>
        </h3>
      </div>
    );
  }

  function RunMilestoneSection() {
    return IsLoading ? (
      <div></div>
    ) : (
      <div className={"maxheight tis-title-section-outerdiv"}>
        <div className={"tis-title-section-innerdiv"}>{Milestone}</div>
      </div>
    );
  }

  function RunTimelineSection() {
    return IsLoading ? (
      <div></div>
    ) : (
      <div className={"maxheight tis-run-timeline-section-outer-div"}>
        <div className={"tis-run-timeline-section-inner-div"}>
          <span className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Start Time:</b>{" "}
              <span className={"tis-fontsize-12"}>
                {StartDate !== "Invalid DateTime" &&
                StartDate !== "N/A" &&
                StartDate !== ""
                  ? getFormattedDate(StartDate)
                  : ""}
              </span>
            </Label>
          </span>
          <span className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              | <b>End Time:</b>{" "}
              <span className={"tis-fontsize-12"}>
                {EndDate !== "Invalid DateTime" &&
                EndDate !== "N/A" &&
                EndDate !== ""
                  ? getFormattedDate(EndDate)
                  : ""}
              </span>
            </Label>
          </span>
        </div>
      </div>
    );
  }

  function NewMachineSection() {
    return IsLoading || currentRunIndex === -1 ? (
      <div></div>
    ) : (
      <div className={"tis-run-fields-section-outer-div"}>
        <div className={"tis-run-fields-section-inner-div"}>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Machine Type:</b>{" "}
              <span className={"tis-fontsize-12"}>{MachineType}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Machine Name:</b>{" "}
              <span className={"tis-fontsize-12"}>{MachineName} </span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Cores:</b>{" "}
              <span className={"tis-fontsize-12"}>{CoreCount}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>RAM:</b>{" "}
              <span className={"tis-fontsize-12"}>{RamSize} GB</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Disk Size:</b>{" "}
              <span className={"tis-fontsize-12"}>
                {truncValue(DiskSize)} GB
              </span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Disk Type:</b>{" "}
              <span className={"tis-fontsize-12"}>{DiskType}</span>
            </Label>
          </div>
        </div>
      </div>
    );
  }

  function ProductSection() {
    return IsLoading ? (
      <div></div>
    ) : (
      <div className={"tis-run-fields-section-outer-div"}>
        <div className={"tis-run-fields-section-inner-div"}></div>
      </div>
    );
  }

  function MachineSection() {
    return IsLoading ? (
      <div></div>
    ) : (
      <div className={"tis-run-fields-section-outer-div"}>
        <div className={"tis-run-fields-section-inner-div"}>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Product:</b>{" "}
              <span className={"tis-fontsize-12"}>{Product}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>SKU:</b> <span className={"tis-fontsize-12"}>{Sku}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Baseline build:</b>{" "}
              <span className={"tis-fontsize-12"}>{BaselineBuildRevision}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Target build:</b>{" "}
              <span className={"tis-fontsize-12"}>{TargetBuildRevision}</span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Public IP:</b>{" "}
              <span className={"tis-fontsize-12"}>{PublicIp} </span>
            </Label>
          </div>
          <div className={"tis-margin-bottom-5"} tabIndex={0}>
            <Label className="summary-item-label">
              <b>Device ID:</b>{" "}
              <span className={"tis-fontsize-12"}>{DeviceId} </span>
            </Label>
          </div>
        </div>
      </div>
    );
  }

  let tiles = [
    {
      item: <RunIdStatusSection />,
      defaultPosition: {
        col: 1,
        colSpan: 1,
        rowSpan: 2,
      },
      reorderable: false,
      resizable: false,
      style: { borderStyle: "none" },
    },
    {
      item: <RunMilestoneSection />,
      defaultPosition: {
        col: 2,
        colSpan: 3,
        rowSpan: 1,
      },
      reorderable: false,
      resizable: false,
      style: { borderStyle: "none" },
    },
    {
      item: <RunTimelineSection />,
      defaultPosition: {
        col: 2,
        colSpan: 3,
        rowSpan: 1,
      },
      reorderable: false,
      resizable: false,
      style: { borderStyle: "none" },
    },
    {
      item: <NewMachineSection />,
      defaultPosition: {
        col: 5,
        colSpan: 2,
        rowSpan: 2,
      },
      reorderable: false,
      resizable: false,
      style: { borderStyle: "none" },
    },
    {
      item: <MachineSection />,
      defaultPosition: {
        col: 7,
        colSpan: 2,
        rowSpan: 2,
      },
      reorderable: false,
      resizable: false,
      style: { borderStyle: "none" },
    },
  ];

  return (
    <React.Fragment>
      <Card
        style={{
          height: "150px",
          overflowY: "auto",
        }}
      >
        <CardBody style={{ padding: 0 }}>
          <TileLayout
            columns={8}
            rowHeight={75}
            gap={{
              rows: 0,
              columns: 0,
            }}
            items={tiles}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default RunDetailsTile;
