import axios from "axios";
import { globalConfig } from "../../Configuration/config";

export async function SetRepresentativeRun(RunId) {
  const api = globalConfig.config.wl_apiUrl;
  return axios.post(`${api}Run/RepresentativeRun/?api-version=1.0`, RunId);
}

export async function SetToggleRunsAsAnomalous(data, anomalous) {
  const api = globalConfig.config.wl_apiUrl;
  return axios.post(`${api}Run/ToggleRunsAsAnomalous/?api-version=1.0`, {
    runIds: data,
    isAnomalous: anomalous,
  });
}