import React, { useEffect, useRef, useState } from "react";
import RunDetails from "./RunDetails.js";
import {
  RunSummaryContext,
  RunSummaryContextProvider,
} from "../Contexts/RunSummaryContextNV.js";
import ValidationTile from "./ValidationTile";
import "./../Styles/RunDetails.scss";
import DiskUtilization from "./DiskUtilization.jsx";
import CPUUtilization from "./CPUUtilization.jsx";
import UpdateTime from "./UpdateTime.jsx";
import NetworkAnalysis from "./NetworkAnalysis.jsx";
import DiskFootprint from "./DiskFootprint.jsx";
import NetworkVisualisation from "./NetworkVisualisation.jsx";
import ManageMultipleRuns from "./ManageMultipleRuns.jsx";
import DiskUtilizationNV from "./DiskUtilizationNV.jsx";
import PowerUsage from "./PowerUsage.jsx";
import RunLogs from "./RunLogs.jsx";
import FlameGraphs from "./FlameGraphs.jsx";

function RunSummaryPage(props) {
  //const [isCollapse, setIsCollapse] = useState(true);
  const [showLogs, setShowLogs] = useState(false);
  const [hasLogs, setHasLogs] = useState(false);

  const [showGraphs, setShowGraphs] = useState(false);
  const [hasGraphs, setHasGraphs] = useState(false);

  const toggleShowLogs = () => {
    if (!showLogs && showGraphs) toggleShowGraphs();
    setShowLogs(!showLogs);
  };

  const toggleShowGraphs = () => {
    if (showLogs && !showGraphs) toggleShowLogs();
    setShowGraphs(!showGraphs); // && hasGraphs
  };

  function handleClick(event) {
    if (
      divLogsRef.current.style.width !== "0px" &&
      divLogsRef.current.style.width !== "10px" &&
      (event.y < divLogsRef.current.offsetTop ||
        event.x > divLogsRef.current.offsetWidth)
    )
      setShowLogs(false && hasLogs);

    if (
      divGraphsRef.current.style.width !== "0px" &&
      divGraphsRef.current.style.width !== "10px" &&
      (event.y < divGraphsRef.current.offsetTop ||
        event.x > divGraphsRef.current.offsetWidth)
    )
      setShowGraphs(false);
  }
  const divLogsRef = useRef(null);
  const divGraphsRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <React.Fragment>
      <RunSummaryContextProvider>
        <div style={{ marginTop: 5, width: "1470px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ marginTop: 5, width: "1460px" }}>
              <RunDetails
                trackException={props.trackException}
                trackWarning={props.trackWarning}
              />
            </div>
          </div>

          <div
            id="logs-sidenav"
            class="logs-sidenav"
            ref={divLogsRef}
            style={{
              visibility: showLogs ? "" : "hidden",
              width: showLogs ? "350px" : "0px",
              height: showLogs ? "700px" : "10px",
            }}
          >
            <div class="drawerCloseBtnContainer">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={toggleShowLogs}
              >
                &times;
              </a>
            </div>
            <RunLogs SetHasLogs={setHasLogs} />
          </div>
          <div
            id="graphs-sidenav"
            class="logs-sidenav"
            ref={divGraphsRef}
            style={{
              visibility: showGraphs ? "" : "hidden",
              width: showGraphs ? "550px" : "0px",
              height: showGraphs ? "700px" : "10px",
            }}
          >
            <div class="drawerCloseBtnContainer">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={toggleShowGraphs}
              >
                &times;
              </a>
            </div>

            <FlameGraphs SetHasGraphs={setHasGraphs} />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <ManageMultipleRuns
                  toggleShowLogs={toggleShowLogs}
                  toggleShowGraphs={toggleShowGraphs}
                />
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <UpdateTime
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <PowerUsage
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <ValidationTile />
                </div>
              </div>

              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <CPUUtilization
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>

              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <DiskUtilization
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                  <DiskUtilizationNV
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <NetworkAnalysis
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <DiskFootprint
                    toggleShowLogs={toggleShowLogs}
                    toggleShowGraphs={toggleShowGraphs}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5, width: "1500px" }}>
                <div className="panelbar-wrapper">
                  <NetworkVisualisation />
                </div>
              </div>
            </div>
          </div>

          {/* More tiles go here to present all run related data.
              The final set could be different between Release and Experiment validations. */}
        </div>
      </RunSummaryContextProvider>
    </React.Fragment>
  );
}

export default RunSummaryPage;
