export const Load = "Loading...";
export const noData = "No data found for the selected filters";
export const noRpItems = "No Repair Items found for this incident"
export const ProvideId = ["Please Provide the Id of Work Item to be linked", 'info'];
export const CreatingWorkItem =
  ["Do not close the window - Creating ADO Work Item", 'warning'];
export const RepairInvToCreate =
  ["Work Item can only be created when Triage Value is either Investigate or any Repair", 'info'];
export const RepairInvToLink =
  ["Work Item can only be linked when Triage Value is either Investigate or any Repair",  'info'];
export const LinkingWorkItem = (InputId) => {
  return [`Do not close the window - Linking of ADO Work Item ${InputId} to IcM in progress`, 'warning'];
};
export const Exists = (InputId) => {
  return [`Work Item with Id ${InputId} already exists`, 'info'];
};
export const AlreadyAdded = ["WorkItem Already Added",  'info'];
export const AlreadyLinked = ["WorkItem Already Linked", 'info']
export const error = ["Some Error Occured", 'error'];
export const Linked_AddedSuccessfully = (id, add_link) => {
  if (add_link) return [`Work Item with Id ${id} is successfully linked`, 'success'];
  else return [`Work Item with Id ${id} is successfully created`, 'success'];
};
export const SavedSuccessfully = ["Saved Successfully", 'success'];
export const NotApplicable = ["Already Triaged as Not Applicable", 'info']
export const AlreadyOpportunity = ["Work item already exists", 'info']
export const AlreadyInEdit = (qs) => {
  return [`Quality Signal '${qs}' is already in Editing Mode'`, 'info']
}
export const AlreadyTriaged = (id) =>{
  if(id === 5) return ["", 'info']
  if(id === 4) return ["Already Triaged as Investigate", 'info']
  else return ["Already Triaged as Opportunity", 'info']
} 

export const NewRowInEdit = ["Already a new row is in editing mode", 'info']
export const SavingNotApplicable = ["Do not close the window - Saving Triage Value as Not Applicable", 'warning']
export const Saving = ["Do not close the window - Saving", 'warning']
export const AlreadySaved = ["Already Saved", 'info']
export const ExSumDataLoaded = ["Executive Summary Data Loaded", 'success']
export const DetailsLoaded = ["Incident Details loaded", 'success']
export const CommentSaved = ["Comment Saved Successfully", 'success']
export const CommentSaveFailed = ["Some error occured while Saving. Please try again later.", 'error']
