import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import {
  bytestoMB,
  formatBytes,
  numberWithCommas,
  convertMstoSec,
  convertToPercentage,
} from "./Conversions";
import { saveAs } from "file-saver";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function DiskFootprint({ toggleShowLogs, toggleShowGraphs }) {
  const {
    RunId,
    ScanTimeMilliseconds,
    setScanTimeMilliseconds,
    scanOffered,
    setscanOffered,
    DownloadTimeMilliseconds,
    setDownloadTimeMilliseconds,
    DownloadErrorCount,
    setDownloadErrorCount,
    InstallTimeMilliseconds,
    setInstallTimeMilliseconds,
    InstallErrorCount,
    setInstallErrorCount,
    RebootTimeMilliseconds,
    setRebootTimeMilliseconds,
    RebootCount,
    setRebootCount,
    ScanGroupData,
    setScanGroupData,
    DownloadGroupData,
    setDownloadGroupData,
    InstallGroupData,
    setInstallGroupData,
    RebootGroupData,
    setRebootGroupData,
    updatelogs,
    setupdatelogs,
    UpdateLogsData,
    setUpdateLogsData,
    diskspaceData,
    setDiskspaceData,
    fileAccessData,
    setFileAccessData,
    fileCountData,
    setFileCountData,
    diskspaceLogsData,
    setDiskspaceLogsData,
  } = useContext(RunSummaryContextNV);

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const [diskData, setDiskData] = useState(null);
  const cycle0 = diskData?.Logs?.filter((t) => t.Cycle === "0");
  const cycle1 = diskData?.Logs?.filter((t) => t.Cycle === "1");
  const cycle2 = diskData?.Logs?.filter((t) => t.Cycle === "2");

  useEffect(() => {
    const fetchDiskUtilizationDetails = async () => {
      try {
        const res = await API.GetDiskFootprintDetails(RunId);
        if (
          res.data !== null &&
          res.data !== "" &&
          Object.keys(res.data).length
        )
          setDiskData(res.data);
        else setDiskData(null);
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setDiskData(null);
      }
    };
    fetchDiskUtilizationDetails();
  }, [RunId]);

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            <p className="tilefontsize">
              {diskData?.DiskSpace?.DiskSpaceUsageBytes
                ? formatBytes(diskData?.DiskSpace?.DiskSpaceUsageBytes)
                : "--"}
            </p>
            <p className="tileheaderstyle">DISK SPACE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Peak:
              <span className="tilecolorstyle">
                {" "}
                {diskData?.DiskSpace?.PeakDiskSpaceBytes
                  ? formatBytes(diskData?.DiskSpace?.PeakDiskSpaceBytes)
                  : "--"}
              </span>
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              <span className="tilecolorstyle">
                {" "}
                {diskData?.DiskSpace?.OtherDiskSpaceBytes
                  ? formatBytes(diskData?.DiskSpace?.OtherDiskSpaceBytes)
                  : "--"}
              </span>
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskData?.FileCount?.NetFileCount ? (
              <p className="tilefontsize">
                {numberWithCommas(diskData?.FileCount?.NetFileCount)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">FILE COUNT</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Created:
              {diskData?.FileCount?.CreatedFileCount ? (
                <span className="tilecolorstyle">
                  {" "}
                  {numberWithCommas(diskData?.FileCount?.CreatedFileCount)}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Deleted:
              {diskData?.FileCount?.DeletedFileCount ? (
                <span className="tilecolorstyle">
                  {" "}
                  {numberWithCommas(diskData?.FileCount?.DeletedFileCount)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Renamed:
              {diskData?.FileCount?.RenamedFileCount ? (
                <span className="tilecolorstyle">
                  {" "}
                  {numberWithCommas(diskData?.FileCount?.RenamedFileCount)}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskData?.FileAccess?.OpenedFileCount ? (
              <p className="tilefontsize">
                {numberWithCommas(diskData?.FileAccess?.OpenedFileCount)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">FILE ACCESS</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Read:
              {diskData?.FileAccess?.ReadFileCount ? (
                <span className="tilecolorstyle">
                  {" "}
                  {numberWithCommas(diskData?.FileAccess?.ReadFileCount)}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Written:
              {diskData?.FileAccess?.WrittenFileCount ? (
                <span className="tilecolorstyle">
                  {" "}
                  {numberWithCommas(diskData?.FileAccess?.WrittenFileCount)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Disk Footprint{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }
  return (
    <React.Fragment>
      {diskData !== null && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight={"auto"}
              resizable={false}
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={diskTiles}
              columnWidth={476}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default DiskFootprint;
