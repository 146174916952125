import axios from "axios";
import { globalConfig } from "../../Configuration/config";

export async function GetRunDetails(runId) {
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/${runId}?api-version=1.0`);
}

export async function GetValidationDetails(runId) {
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${runId}?api-version=1.0`);
}

export async function GetDiskUtilizationDetails(runId) {
  let widgetId = "DiskUsage";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${runId}/${widgetId}?api-version=1.0`);
}

export async function GetDiskUtilizationDetailsNV(runId) {
  let widgetId = "FileUsage";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${runId}/${widgetId}?api-version=1.0`);
}

export async function GetPowerUsageDetails(runId) {
  let widgetId = "PowerUsage";
  const api = globalConfig.config.wl_apiUrl;

  return axios.get(`${api}Run/Validation/${runId}/${widgetId}?api-version=1.0`);
}

export async function GetCPUUtilizatioDetails(RunId) {
  let widgetId = "CpuUsage";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${RunId}/${widgetId}?api-version=1.0`);
}
export async function GetUpdateTimeDetails(RunId) {
  let widgetId = "UpdateTime";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${RunId}/${widgetId}?api-version=1.0`);
}

export async function GetNetworkDetails(RunId) {
  let widgetId = "UpdateTime";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(
    `${api}Run/Validation/${RunId}/NetworkAnalysis?api-version=1.0`
  );
  return {
    Data: {
      DownloadSize: {
        MetadataBytes: 21000000,
        LcuBytes: 109000000,
        StoreBytes: 101000000,
        OtherBytes: 67000000,
      },
      BandwidthUsage: {
        WuPercent: 41,
        StorePercent: 20,
        OtherPercent: 39,
      },

      TrafficProperties: {
        LcuDownloadSeconds: 113,
        LcuAverageDownloadSpeed: 15,
        DownloadPhaseIdleTimeSeconds: 120,
      },
      Logs: [
        {
          FileName: "Recording.dat",
          FilePath:
            "https://waaslabstorage.file.core.windows.net/vmlogs/DESKTOP-GRGRTPP/waaslab/00000000-1/1/WaaS Lab Performance Trace/Recording.dat",
          SizeBytes: "730000000",
          TimeStamp: "2022-06-01T23:18:56.511Z",
          Annotation:
            "Network trace for online part of Windows Update (1st update cycle)",
          SasUri:
            "https://waaslabstorage.file.core.windows.net/vmlogs/DESKTOP-GRGRTPP/waaslab/00000000-1/1/WaaS%20Lab%20Performance%20Trace/Recording.dat?sv=2021-06-08&ss=bqtf&srt=sco&sp=rwdlacuptfx&se=2022-06-11T16:17:56Z&sig=hhiqVojXGQIFzbjgu8UNDJa9Kp7zb6hdmXSMNPRetzc%3D&_=1654935490246",
        },
      ],
    },
  };
}

export async function GetDiskFootprintDetails(RunId) {
  let widgetId = "DiskFootprint";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${RunId}/${widgetId}?api-version=1.0`);
}
export async function GetNetworkVisualisationDetails(RunId) {
  let widgetId = "NetworkVisualisation";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/Validation/${RunId}/${widgetId}?api-version=1.0`);
}

export async function GetManageRunsDetails(RunId) {
  let widgetId = "ManageWidget";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/${widgetId}/${RunId}/?api-version=1.0`);
}

export async function DownloadRunsExcel(runId) {
  const api = globalConfig.config.wl_apiUrl;
  var url = `${api}Run/GetExportToExcelRunResults/${runId}?api-version=1.0`;
  window.open(url, "", null);
}

export async function GetRunsLogs(RunId) {
  let widgetId = "RunLogs";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/${widgetId}/${RunId}/?api-version=1.0`);
}

export async function GetGraphLogs(RunId) {
  let widgetId = "RunGraphLogs";
  const api = globalConfig.config.wl_apiUrl;
  return axios.get(`${api}Run/${widgetId}/${RunId}/?api-version=1.0`);
}
