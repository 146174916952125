import { useState, useEffect } from "react";
import { filterDetails } from "./Services/ApiService/GetFilters";

export const DefaultFilter = (filterName, initial) => {
  const [Default, setDefault] = useState(initial);
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await filterDetails(filterName);
        var temp = [...Default, ...res.data];
        if (filterName !== "Milestone") {
          setDefault(temp.map((o) => o.name));
        } else {
          setDefault(temp);
        }
      } catch (er) {
        console.log("error", er);
        // props.trackException(er)
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Default;
};

export const NoOp = (filterName, initial) => {
  return;
};
