/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FeedbackWindow } from './feedbackWindow';
import '../../Styles/tool.scss';
import '../../Styles/feedback.scss';
import '@progress/kendo-theme-material/dist/all.scss';
import { Loader } from '@progress/kendo-react-indicators';
import { IFeedBackWindow } from '../../Models/feedbackWindowItem';

const LoadingScreen = () => {
    return (
        <Loader
            size={'large'}
            type={'converging-spinner'}
            style={{ alignSelf: 'center', position: 'fixed', top: '40%', zIndex: 100005, right: '24%' }}
        ></Loader>
    );
};

export const FeedbackWindowFloater: React.FC<IFeedBackWindow> = ({
    item,
    createWorkItemId,
    createIcmIncidentId,
    setShowFeedWindow,
}) => {
    const [Loading, setLoading] = useState<boolean>(false);

    return (
        <div>
            {Loading == true && LoadingScreen()}
            <div
                className={
                    Loading ? 'frozeScreen k-widget k-window feedbackFloater' : 'k-widget k-window feedbackFloater'
                }
                data-role="draggable"
            >
                <div className="k-window-titlebar k-header">
                    <span className="k-window-title" id="feedbackWindow_wnd_title">
                        Feedback
                    </span>
                    <div className="k-window-actions">
                        <a
                            role="button"
                            className="k-button k-bare k-button-icon k-window-action"
                            aria-label="Close"
                            onClick={item.handleOpen}
                        >
                            <span className="k-icon k-i-close"></span>
                        </a>
                    </div>
                </div>
                <FeedbackWindow
                    setLoading={setLoading}
                    item={item}
                    createWorkItemId={createWorkItemId}
                    setShowFeedWindow={setShowFeedWindow}
                    createIcmIncidentId={createIcmIncidentId}
                />
            </div>
        </div>
    );
};
