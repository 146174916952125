import React from "react";
import { withRouter } from "react-router-dom";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
// import Header from './Header.jsx';

const drawerItems = [
  {
    name: "executivesummary",
    displaytext: "Executive Summary",
    selected: true,
    icon: "k-i-grid",
    route: "/",
    routes: ["/", "/run"],
  },
  // { name: 'dashboard', displaytext:"Dashboard", icon: 'k-i-grid', route: '/dashboard' }
];

class DrawerRouterContainer extends React.Component {
  state = {
    expanded: false,
    selectedId: drawerItems.findIndex((x) => x.selected === true),
    isSmallerScreen: window.innerWidth < 768,
  };

  resizeWindow = () => {
    this.setState({ isSmallerScreen: window.innerWidth < 768 });
  };

  handleClick = () => {
    this.setState((e) => ({ expanded: !e.expanded }));
  };

  handleSelect = (e) => {
    this.setState({ selectedId: e.itemIndex, expanded: false });
    this.props.history.push(e.itemTarget.props.route);
  };

  getSelectedItem = (pathName) => {
    let currentPath = drawerItems.find(
      (item) => item.routes.indexOf(pathName) >= 0
    );
    if (currentPath.name) {
      return currentPath.name;
    }
  };

  componentDidUpdate() {
    try {
      const parent = window.parent;
      if (parent) {
        parent.postMessage(
          { url: this.props.location.pathname, demo: true },
          "*"
        );
      }
    } catch (err) {
      console.warn("Cannot access iframe");
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeWindow, false);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  }

  render() {
    let selected = this.getSelectedItem(this.props.location.pathname);

    return (
      <React.Fragment>
        <Drawer
          className={"maxheight"}
          expanded={this.state.expanded}
          animation={{ duration: 100 }}
          items={drawerItems.map((item) => ({
            ...item,
            text: item.displaytext,
            selected: item.name === selected,
          }))}
          position="start"
          mode={this.state.isSmallerScreen ? "overlay" : "push"}
          mini={this.state.isSmallerScreen ? false : true}
          onOverlayClick={this.handleClick}
          onSelect={this.handleSelect}
        >
          <DrawerContent className={"maxheight"}>
            {this.props.children}
          </DrawerContent>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withRouter(DrawerRouterContainer);
