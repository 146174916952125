import moment from "moment";
import React from "react";
import { globalConfig } from "../Configuration/config";
import { Banner } from "../Shared/src/Components/banner/banner";

const GetHeaderData = () => {
  return globalConfig.config.wl_shared;
};

class Header extends React.Component {
  render() {
    return <Banner item={GetHeaderData()} />;
  }
}
export default Header;
