import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { MSRangeChart } from "./Charts/MSRangeChart";
import * as API from "../Services/ApiService/GetRunDetails";
import "../Styles/_networkVisualisation.scss";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import "../Styles/_networkVisualisation.scss";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";

function NetworkVisualisation(props) {
  const { RunId } = useContext(RunSummaryContextNV);

  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [fromVal, setFromVal] = useState("");
  const [toVal, setToVal] = useState("");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState();
  const [realData, setRealData] = useState([]);
  const [zoomDisabled, setZoomDisabled] = useState(true);

  /************************* */

  const barHeight = 220;
  const [final, setFinal] = useState([]);
  const [original, setOriginal] = useState([]);
  const [totalWidth, setTotalWidth] = useState(1500);
  const [measureBars, setMeasureBars] = useState([]);
  const [calculatedBarWidth, setCalculatedBarWidth] = useState(0);
  const [totalMbs, setTotalMbs] = useState(0);
  const [partialDisplay, setPartialDisplay] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  let mbWidth = 0;
  let realIndex = 0;

  /************************* */

  useEffect(() => {
    const fetchNetworkVisualisationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetNetworkVisualisationDetails(RunId);
        console.log(res.status, typeof res.status);
        if (res.status === 204) {
          setLoading(false);
          return;
        }
        let tmb = Math.round(
          res.data.ByteRange[res.data.ByteRange.length - 1].End / 1048576
        );
        setTotalMbs(tmb);

        mbWidth = Math.floor(
          1500 /
            Math.round(
              res.data.ByteRange[res.data.ByteRange.length - 1].End / 1048576
            )
        );
        let tmpMbs = [];
        for (let i = 0; i < tmb; i++) {
          tmpMbs.push(i);
        }
        setMeasureBars(tmpMbs);
        setCalculatedBarWidth(mbWidth);

        console.log(new Date());
        let temp = [];
        let currentSlice = 0;
        res.data.ByteRange.forEach((range, index) => {
          if (range.Start > 0 && index === 0) {
            temp.push(...addSlices(0, range.Start));
            const cloned = Object.assign({}, range);
            temp.push(cloned);
          } else if (
            index < res.data.ByteRange.length - 1 &&
            range.End < res.data.ByteRange[index + 1].Start
          ) {
            const cloned = Object.assign({}, range);
            temp.push(cloned);
            temp.push(
              ...addSlices(range.End, res.data.ByteRange[index + 1].Start)
            );
          }
        });

        temp.forEach((range, index) => {
          range.mbStart = (range.Start / 1048576).toFixed(2);
          range.mbEnd = (range.End / 1048576).toFixed(2);
          range.mbs = range.mbEnd - range.mbStart;
          index === 0 ? (range.gap = range.mbs) : (range.gap = 0.0);
          if (index > 0) {
            range.gap = range.mbStart - temp[index - 1].mbEnd;
          }
        });

        res.data.ByteRange.forEach((range, index) => {
          range.mbStart = (range.Start / 1048576).toFixed(2);
          range.mbEnd = (range.End / 1048576).toFixed(2);
          range.mbs = range.mbEnd - range.mbStart;
          range.index = index;
          index === 0 ? (range.gap = range.mbs) : (range.gap = 0.0);
          if (index > 0) {
            range.gap = range.mbStart - res.data.ByteRange[index - 1].mbEnd;
          }
        });

        setOriginal(res.data?.ByteRange);
        setData(res.data?.ByteRange);
        setTo(res.data.ByteRange[res.data?.ByteRange.length - 1].y);
        setLoading(false);
      } catch (er) {
        console.log(er);
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchNetworkVisualisationDetails();
  }, [RunId]);

  const addSlices = (start, upperLimit) => {
    let slices = [];

    while (upperLimit - start > 1048575) {
      slices.push({ Start: start, End: start + 1048576, Real: false });
      start += 1048576;
    }
    if (upperLimit - start > 1) {
      slices.push({ Start: start, End: upperLimit, Real: false });
    }
    return slices;
  };

  useEffect(() => {
    let disabled =
      fromVal === "" ||
      toVal === "" ||
      Number(fromVal) < 0 ||
      Number(toVal) > original[original.length - 1].mbEnd ||
      Number(fromVal) > Number(toVal);
    setZoomDisabled(disabled);
  }, [fromVal, toVal]);

  /******************************* */
  const onBarClickHandler = (item) => {
    setPartialDisplay(true);
    setIsZoomed(true);
    if (data.length !== original.length) {
      setPartialDisplay(false);
      setIsZoomed(false);
      setData(original);
      setTotalMbs(Math.round(original[original.length - 1].End / 1048576));
      mbWidth = Math.floor(
        1500 / Math.round(original[original.length - 1].End / 1048576)
      );
      let tmpMbs = [];
      for (let i = 0; i < totalMbs; i++) {
        tmpMbs.push(i);
      }
      setMeasureBars(tmpMbs);
      setCalculatedBarWidth(mbWidth);
      return;
    }

    let itemIndex = 0;

    itemIndex = original.findIndex(
      (i) => i.mbEnd === Math.trunc(item.mbEnd) + ".00"
    );

    let startItemIndex = 0;
    let endItemIndex = 0;

    if (original[itemIndex].mbStart > 10) {
      endItemIndex = itemIndex + 1;
      startItemIndex = original.findIndex(
        (i) => i.mbStart >= original[itemIndex].mbEnd - 10
      );
    } else {
      startItemIndex = 0;
      endItemIndex = original.findIndex((i) => i.mbEnd >= 10);
    }

    let tmp = original.slice(startItemIndex, endItemIndex);
    tmp.forEach((i, ix) => {
      i.mbEndN = Number(i.mbEnd);
      i.mbStartN = Number(i.mbStart);
    });
    if (tmp.length > 0) {
      setTotalMbs(tmp[tmp.length - 1].mbEndN - tmp[0].mbStartN);
      mbWidth = Math.floor(1500 / tmp[tmp.length - 1].mbEndN - tmp[0].mbStartN);
      setCalculatedBarWidth(mbWidth);
      let tmpMbs = [];
      for (let i = tmp[0].mbStartN; i < tmp[tmp.length - 1].mbEndN; i++) {
        tmpMbs.push(i);
      }
      setMeasureBars(tmpMbs);
      setData(tmp);
    }
  };

  /******************************* */

  const resetPlots = () => {
    setFrom(0);
    setTo(0);
    setData([]);
    setRefresh(!refresh);
    setZoomDisabled(true);
  };

  const handleZoomRange = () => {
    setPartialDisplay(true);
    setIsZoomed(true);
    let itemIndex = 0;
    let startItemIndex = 0;
    startItemIndex = original.findIndex(
      (i) => parseInt(i.mbStart) >= parseInt(Math.trunc(fromVal))
    );

    let endItemIndex = 0;
    endItemIndex = original.findIndex(
      (i) => parseInt(i.mbStart) >= parseInt(Math.trunc(toVal))
    );

    if (startItemIndex < 0 || endItemIndex < 0) {
      setData([]);
      let tmp = original.slice(startItemIndex, endItemIndex);
      if (tmp.length > 0) {
        setTotalMbs(tmp[tmp.length - 1].mbEnd - tmp[0].mbStart);
        mbWidth = Math.floor(1500 / tmp[tmp.length - 1].mbEnd - tmp[0].mbStart);
        setCalculatedBarWidth(mbWidth);
        let tmpMbs = [];
        for (let i = tmp[0].mbStart; i < tmp[tmp.length - 1].mbEnd; i++) {
          tmpMbs.push(i);
        }
        setMeasureBars(tmpMbs);
        setData(tmp);
      }
      return;
    }

    let tmp = original.slice(startItemIndex, endItemIndex);
    if (tmp.length > 0) {
      setTotalMbs(tmp[tmp.length - 1].mbEnd - tmp[0].mbStart);
      mbWidth = Math.floor(1500 / tmp[tmp.length - 1].mbEnd - tmp[0].mbStart);
      setCalculatedBarWidth(mbWidth);
      let tmpMbs = [];
      for (let i = tmp[0].mbStart; i < tmp[tmp.length - 1].mbEnd; i++) {
        tmpMbs.push(i);
      }
      setMeasureBars(tmpMbs);
      setData(tmp);
    } else {
      setData([]);
    }
  };

  const handleReset = () => {
    setIsZoomed(false);
    if (data.length === original.length) return;
    setPartialDisplay(false);
    setData(original);
    setTotalMbs(Math.round(original[original.length - 1].End / 1048576));
    mbWidth = Math.floor(
      1500 / Math.round(original[original.length - 1].End / 1048576)
    );
    let tmpMbs = [];
    for (let i = 0; i < totalMbs; i++) {
      tmpMbs.push(i);
    }
    setMeasureBars(tmpMbs);
    setCalculatedBarWidth(mbWidth);
  };

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Network Visualization{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!Loading && data && data.length > 0 && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={false} title={getHeader()}>
            {
              <div style={{ padding: 10, marginLeft: 15 }} className="zoomForm">
                <h2>Select range to zoom</h2>
                <label>From: </label>
                <input
                  type="number"
                  value={fromVal}
                  onChange={(e) => {
                    setFromVal(Number(e.target.value));
                  }}
                  title="from"
                  placeholder=""
                />
                <label>To: </label>
                <input
                  type="number"
                  value={toVal}
                  onChange={(e) => {
                    setToVal(Number(e.target.value));
                  }}
                  title="to"
                  placeholder=""
                />
                <button
                  onClick={handleZoomRange}
                  disabled={zoomDisabled}
                  className={zoomDisabled ? "zoom-btn-disabled" : "zoom-btn"}
                >
                  Zoom
                </button>
                <button onClick={handleReset} className="reset-btn">
                  Reset
                </button>
              </div>
            }
            <div className="range-chart">
              {data && data.length > 0 ? (
                <MSRangeChart
                  isZoomed={isZoomed}
                  data={data}
                  onBarClickHandler={onBarClickHandler}
                  originalArrayLength={original.length}
                  partialDisplay={partialDisplay}
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    margin: "0px auto",
                    borderTop: "2px solid rgb(51, 51, 51)",
                    borderBottom: "2px solid rgb(51, 51, 51)",
                    width: 1550,
                  }}
                ></div>
              )}
            </div>
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={false} title={getHeader()}></PanelBarItem>
          <ExceptionTextComponent exceptionText={exceptionText} />
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default NetworkVisualisation;
