/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IFeedBackSuccessWindow } from '../../Models/feedbackWindowItem';
import '../../Styles/feedback.scss';
import '@progress/kendo-theme-material/dist/all.scss';
import '../../Styles/tool.scss';

export const FeedbackSuccess: React.FC<IFeedBackSuccessWindow> = ({ item }) => {
    const getWorkItemLink = () => {
        return item.workItemLink + '&id=' + item.workItemId;
    };

    const getIcmLink = () => {
        return item.icmPortalLink + '/imp/IncidentDetails.aspx?id=' + item.icmIncidentId;
    };

    return (
        <>
            <div id="feedbackProcessing" className="k-widget k-window" data-role="draggable">
                <div className="k-window-titlebar k-header">
                    <span className="k-window-title" id="feedbackProcessing_wnd_title">
                        <span id="successMsg">
                            {item.workItemId && (
                                <>
                                    VSO task #
                                    <a href={getWorkItemLink()} target="_blank" rel="noreferrer">
                                        {item.workItemId}
                                    </a>
                                    created successfully
                                </>
                            )}
                            {item.icmIncidentId && (
                                <>
                                    Incident Created Successfully #
                                    <a href={getIcmLink()} target="_blank" rel="noreferrer">
                                        {item.icmIncidentId}
                                    </a>
                                </>
                            )}
                        </span>
                    </span>
                    <div className="k-window-actions">
                        <a
                            role="button"
                            className="k-button k-bare k-button-icon k-window-action"
                            aria-label="Close"
                            onClick={item.closeSuccessmessage}
                        >
                            <span className="k-icon k-i-close"></span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
