import React, { useEffect, useState } from "react";

export const MSRangeChart = ({
  isZoomed,
  data,
  onBarClickHandler,
  originalArrayLength,
  partialDisplay,
}) => {
  const barHeight = 220;
  const [measureBars, setMeasureBars] = useState([]);
  const [calculatedBarWidth, setCalculatedBarWidth] = useState(0);

  let mbWidth = 0;
  let currentId = 0;

  const keyValue = () => {
    return ++currentId;
  };
  let totalMbs = 0;

  useEffect(() => {
    totalMbs = (data[data.length - 1].mbEnd - data[0].mbStart).toFixed(2);
    let tmpMeasureBars = [];

    if (data.length === originalArrayLength) {
      for (let i = 0; i < totalMbs; i++) {
        tmpMeasureBars.push(i);
      }
    } else {
      for (let i = data[0].mbStartN; i < data[data.length - 1].mbEndN; i++) {
        tmpMeasureBars.push(i);
      }
    }

    setMeasureBars(tmpMeasureBars);
    setCalculatedBarWidth(
      Math.floor(1500 / ((data[data.length - 1].End - data[0].Start) / 1048576))
    );
  }, [data]);

  let barsResult = (
    <div style={{ textAlign: "center" }}>
      <h2>No data to display.</h2>
    </div>
  );
  if (data && data.length > 0 && !isZoomed) {
    barsResult = (
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        {data.map((i, ndx) => {
          const color = "#1374DD";
          const border = ".2px solid #0f5db1";

          const barWidth = i.mbs * calculatedBarWidth;
          let prevBarWidth;
          let title = "";
          if (ndx > 0) {
            prevBarWidth =
              (i.mbStart - data[ndx - 1].mbEnd) * calculatedBarWidth;
            title = data[ndx - 1].mbEnd + "-" + i.mbStart;
          } else {
            prevBarWidth = i.mbStart * calculatedBarWidth;
            title = "0-" + i.mbStart;
          }
          let returnVal = null;
          i.gap !== 0
            ? (returnVal = (
                <React.Fragment>
                  <div
                    style={{
                      width: prevBarWidth,
                      backgroundColor: "#ffffff",
                      height: barHeight,
                      border: "",
                    }}
                    title={title}
                    key={keyValue()}
                  ></div>
                  <div
                    style={{
                      width: barWidth,
                      backgroundColor: color,
                      height: barHeight,
                      border: border,
                    }}
                    title={i.mbStart + "-" + i.mbEnd}
                    key={keyValue()}
                    onClick={() => {
                      onBarClickHandler(i);
                    }}
                  ></div>
                </React.Fragment>
              ))
            : (returnVal = (
                <div
                  style={{
                    width: barWidth,
                    backgroundColor: color,
                    height: barHeight,
                    border: border,
                  }}
                  title={i.mbStart + "-" + i.mbEnd}
                  key={keyValue()}
                  onClick={() => {
                    onBarClickHandler(i);
                  }}
                ></div>
              ));
          return returnVal;
        })}
      </div>
    );
  }

  if (data && data.length > 0 && isZoomed) {
    barsResult = (
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        {data.map((i, ndx) => {
          const color = "#1374DD";
          const border = ".2px solid #0f5db1";

          const barWidth = i.mbs * calculatedBarWidth;
          let prevBarWidth;
          let title = "";
          prevBarWidth =
            ndx === 0
              ? 0
              : (i.mbStart - data[ndx - 1].mbEnd) * calculatedBarWidth;
          title = ndx === 0 ? i.mbStart : data[ndx - 1].mbEnd + "-" + i.mbStart;

          let returnVal = null;
          i.gap !== 0
            ? (returnVal = (
                <React.Fragment>
                  <div
                    style={{
                      width: prevBarWidth,
                      backgroundColor: "#ffffff",
                      height: barHeight,
                      border: "",
                    }}
                    title={title}
                    key={keyValue()}
                  ></div>
                  <div
                    style={{
                      width: barWidth,
                      backgroundColor: color,
                      height: barHeight,
                      border: border,
                    }}
                    title={i.mbStart + "-" + i.mbEnd}
                    key={keyValue()}
                    onClick={() => {
                      onBarClickHandler(i);
                    }}
                  ></div>
                </React.Fragment>
              ))
            : (returnVal = (
                <div
                  style={{
                    width: barWidth,
                    backgroundColor: color,
                    height: barHeight,
                    border: border,
                  }}
                  title={i.mbStart + "-" + i.mbEnd}
                  key={keyValue()}
                  onClick={() => {
                    onBarClickHandler(i);
                  }}
                ></div>
              ));
          return returnVal;
        })}
      </div>
    );
  }

  const measureCycle = () => {
    return !partialDisplay ? 25 : 1;
  };

  const currentBarText = (ix) => {
    return measureBars[ix] ? parseInt(measureBars[ix]) : 0;
  };

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          fontSize: 8,
          marginTop: 15,
          margin: "0 auto",
          width: 1550,
        }}
      >
        &nbsp;
      </div>
      <div style={{ border: "2px solid #333", margin: "0 auto", width: 1550 }}>
        {barsResult}
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            paddingTop: 5,
          }}
        >
          {!partialDisplay
            ? measureBars.map((m, idx) => {
                if (idx % measureCycle() === 0 && idx !== 0)
                  return (
                    <React.Fragment>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            width: calculatedBarWidth,
                            textAlign: "left",
                            marginBottom: 0,
                          }}
                          key={keyValue()}
                        >
                          |
                        </div>
                        <div
                          style={{
                            width: calculatedBarWidth,
                            left: "50%",
                            transform: "translateX(-50%)",
                            marginTop: 0,
                          }}
                          key={keyValue()}
                        >
                          {currentBarText(idx)}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                return <div style={{ width: calculatedBarWidth }}></div>;
              })
            : measureBars.map((m, idx) => {
                return (
                  <React.Fragment>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          width: calculatedBarWidth,
                          textAlign: "left",
                          marginBottom: 0,
                        }}
                        key={keyValue()}
                      >
                        |
                      </div>
                      <div
                        style={{
                          width: calculatedBarWidth,
                          textAlign: "left",
                          marginTop: 0,
                        }}
                        key={keyValue()}
                      >
                        {currentBarText(idx)}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: 25,
          marginTop: 15,
          margin: "0 auto",
          width: 1550,
          borderBottom: "2px solid #333",
        }}
      >
        PSF ranges downloaded (megabytes)
      </div>
    </React.Fragment>
  );
};
