import axios from "axios";
import { globalConfig } from "../../Configuration/config";

export async function fetchMyAPI(body, PageNumber, PageSize) {
  const api = globalConfig.config.wl_apiUrl;
  var url = `${api}Run/search?PageNumber=${PageNumber}&PageSize=${PageSize}&api-version=1.0`;

  return axios({
    url: url,
    method: "POST",
    data: body,
  });
}

export async function fetchMyAPIwithoutPaging(body) {
  const api = globalConfig.config.wl_apiUrl;
  return axios({
    url: `${api}Run/RunsByProductRelease?productName=All&release=All&api-version=1.0`,
    method: "GET",
    data: body,
  });
}
