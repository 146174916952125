export const bytestoMB = (value) => {
  let posValue = value;
  let formatDigits = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (value < 0) {
    posValue *= -1;
  }

  if (posValue < 1024) {
    return `${value.toLocaleString("en-US")} B`;
  }

  if (posValue < 1024 * 1024) {
    return `${(value / 1024).toLocaleString("en-US", formatDigits)} KB`;
  }

  if (posValue < 1024 * 1024 * 1024) {
    return `${(value / (1024 * 1024)).toLocaleString(
      "en-US",
      formatDigits
    )} MB`;
  }

  return `${(value / (1024 * 1024 * 1024)).toLocaleString(
    "en-US",
    formatDigits
  )} GB`;
};

export const valueToJs = (value) => {
  let posValue = value;
  let formatDigits = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (value < 0) {
    posValue *= -1;
  }

  if (posValue < 1024) {
    return `${value.toLocaleString("en-US")} mJ`;
  }

  if (posValue < 1024 * 1024) {
    return `${(value / 1024).toLocaleString("en-US", formatDigits)} J`;
  }

  if (posValue < 1024 * 1024 * 1024) {
    return `${(value / (1024 * 1024)).toLocaleString(
      "en-US",
      formatDigits
    )} KJ`;
  }

  return `${(value / (1024 * 1024 * 1024)).toLocaleString(
    "en-US",
    formatDigits
  )} MJ`;
};

export const valueToKgs = (value) => {
  let posValue = value;
  let formatDigits = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (value < 0) {
    posValue *= -1;
  }

  if (posValue < 1024) {
    return `${value.toLocaleString("en-US", formatDigits)} gCO2e`;
  }

  if (posValue < 1024 * 1024) {
    return `${(value / 1024).toLocaleString("en-US", formatDigits)} kgCO2e`;
  }

  if (posValue < 1024 * 1024 * 1024) {
    return `${(value / (1024 * 1024)).toLocaleString(
      "en-US",
      formatDigits
    )} tCO2e`;
  }

  return `${(value / (1024 * 1024 * 1024)).toLocaleString(
    "en-US",
    formatDigits
  )} CO2e`;
};

export const valueToWh = (value) => {
  let posValue = value;
  let formatDigits = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (value < 0) {
    posValue *= -1;
  }

  if (posValue < 1024) {
    return `${value.toLocaleString("en-US", formatDigits)} Wh`;
  }

  if (posValue < 1024 * 1024) {
    return `${(value / 1024).toLocaleString("en-US", formatDigits)} Wh`;
  }

  if (posValue < 1024 * 1024 * 1024) {
    return `${(value / (1024 * 1024)).toLocaleString(
      "en-US",
      formatDigits
    )} Wh`;
  }

  return `${(value / (1024 * 1024 * 1024)).toLocaleString(
    "en-US",
    formatDigits
  )} Wh`;
};

export const truncValue = (value) => {
  if (isNaN(value)) {
    return 0;
  }
  return Math.floor(value);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  return bytestoMB(bytes);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertMstoSec = (value) => {
  if (value <= 0) return "--";
  return Math.trunc(value / 1000) + " s";
};

export const convertToPercentage = (value, total) => {
  return Math.trunc((value / total) * 100) + "%";
};

export const convertToThousand = (value) => {
  if (isNaN(value)) {
    return "0";
  }

  value += "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(value)) {
    value = value.replace(rgx, "$1" + "," + "$2");
  }
  return value;
};

export const bytestoMBPerSec = (value) => {
  return (value / 1024 / 1024).toFixed(2).toLocaleString("en-US") + " MB/s";
};

export const megabytesToKB = (value) => {
  return (value / 1024).toFixed(2) + " KB";
};

export const roundOff = (value) => {
  return value.toFixed(2) + " MB";
};
