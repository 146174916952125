import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import {
  bytestoMB,
  convertMstoSec,
  convertToPercentage,
  convertToThousand,
  bytestoMBPerSec,
} from "./Conversions";
import { saveAs } from "file-saver";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function NetworkAnalysis({ toggleShowLogs, toggleShowGraphs }) {
  const {
    networkData,
    setnetworkData,
    downloadSizeData,
    setdownloadSizeData,
    metaData,
    setmetaData,
    lcu,
    setlcu,
    store,
    setstore,
    otherdownload,
    setotherdownload,
    bandwidthUsage,
    setbandwidthUsage,
    wupercent,
    setwupercent,
    storePercent,
    setstorePercent,
    otherBandwidthPercent,
    setotherBandwidthPercent,
    trafficProperties,
    settrafficProperties,
    lcuDownloadSeconds,
    setlcuDownloadSeconds,
    lcuAverageDownloadSpeed,
    setlcuAverageDownloadSpeed,
    downloadPhaseIdleTime,
    setdownloadPhaseIdleTime,
    NetworkLogsData,
    setNetworkLogsData,
    networkLogs,
    setnetworkLogs,
    RunId,
  } = useContext(RunSummaryContextNV);
  const [Loading, setLoading] = useState(true);
  const initialDataState = {};
  const [showNetworkData, setShowNetwrokdata] = useState(false);
  const [dataState, setDataState] = React.useState();
  const [logsHeight, setLogsHeight] = React.useState(0);

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const cycle0 = networkLogs.filter((t) => t.Cycle === "0");
  const cycle1 = networkLogs.filter((t) => t.Cycle === "1");
  const cycle2 = networkLogs.filter((t) => t.Cycle === "2");

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onlyNumbers = (str) => {
    if (isNaN(str)) return 0;
    let num = str.match(/\d+/)[0] || 0;
    return Number(num);
  };

  const downloadSizeAddition = () => {
    let total = 0;
    total += metaData ? metaData : 0;
    total += lcu ? lcu : 0;
    total += store ? store : 0;
    total += otherdownload ? otherdownload : 0;

    return bytestoMB(total);
  };

  const wupercentVal = typeof wupercent === "number" ? wupercent + " %" : "0";

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {downloadSizeData ? (
              <p className="tilefontsize">{downloadSizeAddition()}</p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">DOWNLOAD SIZE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Metadata:
              {downloadSizeData && metaData ? (
                <span className="tilecolorstyle"> {bytestoMB(metaData)}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              LCU:
              {downloadSizeData && lcu ? (
                <span className="tilecolorstyle"> {bytestoMB(lcu)}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Store:
              {downloadSizeData && store ? (
                <span className="tilecolorstyle"> {bytestoMB(store)}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              {downloadSizeData && otherdownload ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(otherdownload)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {bandwidthUsage ? (
              <p className="tilefontsize">{wupercentVal}</p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">BANDWIDTH USAGE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle">
              <span className="tilecolorstyle" style={{ visibility: "hidden" }}>
                {" "}
                {storePercent}
              </span>
              <span className="tilecolorstyle"></span>
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Store:
              {bandwidthUsage ? (
                <span className="tilecolorstyle"> {storePercent}%</span>
              ) : (
                <span className="tilecolorstyle">--</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              {bandwidthUsage ? (
                <span className="tilecolorstyle">
                  {" "}
                  {otherBandwidthPercent}%
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle">
              <span className="tilecolorstyle" style={{ visibility: "hidden" }}>
                {" "}
                {storePercent}
              </span>
              <span className="tilecolorstyle"></span>
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {trafficProperties && lcuDownloadSeconds ? (
              <p className="tilefontsize">{lcuDownloadSeconds} s</p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">LCU DOWNLOAD TIME</p>
          </div>
          <div className="second" style={{ paddingRight: "0" }}>
            <p className="tilesubheaderstyle">
              <span className="tilecolorstyle" style={{ visibility: "hidden" }}>
                {" "}
                {storePercent}
              </span>
              <span className="tilecolorstyle"></span>
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              LCU Avg Speed:
              {trafficProperties && lcuAverageDownloadSpeed ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMBPerSec(lcuAverageDownloadSpeed)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Download Phase Idle:
              {trafficProperties && downloadPhaseIdleTime ? (
                <span className="tilecolorstyle">
                  {" "}
                  {downloadPhaseIdleTime} s
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle">
              <span className="tilecolorstyle" style={{ visibility: "hidden" }}>
                {" "}
                {storePercent}
              </span>
              <span className="tilecolorstyle"></span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  useEffect(() => {
    const fetchDiskUtilizationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetNetworkDetails(RunId);
        setLoading(false);
        if (Object.keys(res.data).length != 0) {
          setnetworkData(true);
          setShowNetwrokdata(true);
          if (res.data.Download == null) {
            setdownloadSizeData(false);
          } else {
            setmetaData(res.data.Download.MetadataBytes);
            setlcu(res.data.Download.LcuBytes);
            setstore(res.data.Download.StoreBytes);
            setotherdownload(res.data.Download.OtherBytes);
          }
          if (res.data.Bandwidth == null) {
            setbandwidthUsage(false);
          } else {
            setbandwidthUsage(true);
            setwupercent(res.data.Bandwidth.WuPercent);
            setstorePercent(res.data.Bandwidth.StorePercent);
            setotherBandwidthPercent(res.data.Bandwidth.OtherPercent);
          }
          if (res.data.TrafficProperties == null) {
            settrafficProperties(false);
          } else {
            setlcuDownloadSeconds(
              res.data.TrafficProperties.LcuDownloadSeconds
            );
            setlcuAverageDownloadSpeed(
              res.data.TrafficProperties.LcuAverageDownloadSpeedBytesPerSecond
            );
            setdownloadPhaseIdleTime(
              res.data.TrafficProperties.DownloadPhaseIdleTimeSeconds
            );
          }
          if (res.data.Logs == null) {
            setNetworkLogsData(false);
          } else {
            let logList = [...res.data.Logs];
            setnetworkLogs([...res.data.Logs]);
            const unique = [...new Set(logList.map((item) => item.Cycle))];
            setLogsHeight(logList.length * 25 + (unique.length - 1) * 20);
          }
        } else {
          setnetworkData(false);
          setShowNetwrokdata(false);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchDiskUtilizationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Network Utilization{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }
  return (
    <React.Fragment>
      {!Loading && showNetworkData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight={logsHeight > 150 ? logsHeight : 150}
              gap={{
                rows: 8,
                columns: 6,
              }}
              items={diskTiles}
              columnWidth={477}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default NetworkAnalysis;
