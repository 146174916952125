import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import { bytestoMB, convertMstoSec, convertToPercentage } from "./Conversions";
import { saveAs } from "file-saver";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function CPUUtilization({ toggleShowLogs, toggleShowGraphs }) {
  const {
    RunId,
    TotalTime,
    setTotalTime,
    OnlineUsage,
    setOnlineUsage,
    ShutdownUsage,
    setShutdownUsage,
    BootUsage,
    setBootUsage,
    TotalLoad,
    setTotalLoad,
    System,
    setSystem,
    Defender,
    setDefender,
    Other,
    setOther,
    IdleTimePercent,
    setIdleTimePercent,
    TotalThreads,
    setTotalThreads,
    WaitTime,
    setWaitTime,
    ReadyTime,
    setReadyTime,
    systemUsage,
    setSystemUsage,
    defenderUsage,
    setDefenderUsage,
    otherUsage,
    setOtherUsage,
    cpulogs,
    setCpuLogs,
    CpuUsageData,
    setCpuUsageData,
    CpuUtilizationdata,
    setCpuUtilizationdata,
    CpuPerformancedata,
    setCpuPerformancedata,
    cpuLogsdata,
    setcpuLogsdata,
  } = useContext(RunSummaryContextNV);

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const cycle0 = cpulogs.filter((t) => t.Cycle === "0");
  const cycle1 = cpulogs.filter((t) => t.Cycle === "1");
  const cycle2 = cpulogs.filter((t) => t.Cycle === "2");

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let cpuTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {CpuUsageData ? (
              <p className="tilefontsize">{convertMstoSec(TotalTime)}</p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">TIME</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Online:
              {CpuUsageData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(OnlineUsage)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Shutdown:
              {CpuUsageData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(ShutdownUsage)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Boot:
              {CpuUsageData ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(BootUsage)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {CpuUtilizationdata ? (
              <p className="tilefontsize">{TotalLoad}%</p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">UTILIZATION</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              System:
              {CpuUtilizationdata ? (
                <span className="tilecolorstyle"> {System}%</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Defender:
              {CpuUtilizationdata ? (
                <span className="tilecolorstyle"> {Defender}%</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>

            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              {CpuUtilizationdata ? (
                <span className="tilecolorstyle"> {Other}%</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Idle:
              {IdleTimePercent ? (
                <span className="tilecolorstyle"> {IdleTimePercent}%</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {CpuPerformancedata ? (
              <p className="tilefontsize">{TotalThreads}</p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">THREADS</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Wait Time:
              {CpuPerformancedata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(WaitTime)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Ready Time:
              {CpuPerformancedata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(ReadyTime)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  const [showCPUData, setShowCPUData] = useState(false);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchCPUUtilizationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetCPUUtilizatioDetails(RunId);
        setLoading(false);
        if (Object.keys(res.data).length != 0) {
          setShowCPUData(true);
          if (res.data.CpuUsage == null) {
            setCpuUsageData(false);
          } else {
            setTotalTime(res.data.CpuUsage.UsageTimeMilliseconds);
            setOnlineUsage(res.data.CpuUsage.OnlineUsageMilliseconds);
            setShutdownUsage(res.data.CpuUsage.ShutdownUsageMilliseconds);
            setBootUsage(res.data.CpuUsage.BootUsageMilliseconds);
            setSystemUsage(res.data.CpuUsage.SystemUsageMilliseconds);
            setDefenderUsage(res.data.CpuUsage.DefenderUsageMilliseconds);
            setOtherUsage(res.data.CpuUsage.OtherUsageMilliseconds);
          }
          if (res.data.CpuUtilization == null) {
            setCpuUtilizationdata(false);
          } else {
            setTotalLoad(res.data.CpuUtilization.LoadPercent);
            setSystem(res.data.CpuUtilization.SystemLoadPercent);
            setDefender(res.data.CpuUtilization.DefenderLoadPercent);
            setOther(res.data.CpuUtilization.OtherLoadPercent);
            setIdleTimePercent(res.data.CpuUtilization.IdleTimePercent);
          }
          if (res.data.CpuPerformance == null) {
            setCpuPerformancedata(false);
          } else {
            setTotalThreads(res.data.CpuPerformance.ThreadsNumber);
            setWaitTime(res.data.CpuPerformance.WaitTimeMilliseconds);
            setReadyTime(res.data.CpuPerformance.ReadyTimeMilliseconds);
          }
          if (res.data.Logs == null) {
            setcpuLogsdata(false);
          } else {
            setCpuLogs([...res.data.Logs]);
          }
        } else {
          setShowCPUData(false);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchCPUUtilizationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);
  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          CPU Utilization{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  return (
    <React.Fragment>
      {showCPUData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight="auto"
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={cpuTiles}
              columnWidth={480}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default CPUUtilization;
