import React, { useContext, useEffect, useState } from "react";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import RunDetailsTile from "./RunDetailsTile";
import * as API from "../Services/ApiService/GetRunDetails";
import { noData, error } from "../Constants/Messages";
import { useHistory } from "react-router-dom";
import { HandleError } from "../Services/HandleError";
import { NotificationContext } from "../Contexts/NotificationContext";
import { Loader } from "@progress/kendo-react-indicators";

function RunDetails(props) {
  const {
    RunId,
    setRunId,
    RunNumber,
    setRunNumber,
    Milestone,
    setMilestone,
    Notes,
    setNotes,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    MachineType,
    setMachineType,
    RunStatus,
    setRunStatus,
    BaselineType,
    setBaselineType,
    Product,
    setProduct,
    BaselineBuildRevision,
    setBaselineBuildRevision,
    TargetBuildRevision,
    setTargetBuildRevision,
    Sku,
    setSku,
    DiskType,
    setDiskType,
    CoreCount,
    setCoreCount,
    RamSize,
    setRamSize,
    IsExperiment,
    setIsExperiment,
    InitialLoadingTable,
    setInitialLoadingTable,
    CorrelationId,
    setCorrelationId,
    ShowExceptionPopup,
    setShowExceptionPopup,
    InitialLoading,
    setInitialLoading,
    multipleRunData,
  } = useContext(RunSummaryContextNV);

  const { setMessage } = useContext(NotificationContext);
  const [NoData, setNoData] = useState(true);
  const [Loading, setLoading] = useState(true);

  const [machineName, setMachineName] = useState("");
  const [publicIp, setPublicIp] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [diskSize, setDiskSize] = useState("");
  const [representativeRunStatus, setRepresentativeRunStatus] = useState(false);

  const history = useHistory();

  const fetchIncidentDetails = async () => {
    setInitialLoadingTable(true);
    try {
      const res = await API.GetRunDetails(RunId);
      setInitialLoadingTable(false);
      if (!res.data.runId || res.data.runId === "") {
        setNoData(true);
      } else {
        setNoData(false);
        setRunNumber(res.data.runId);
        setStartDate(res.data.startDate);
        setEndDate(res.data.endDate);
        setNotes(res.data.note);
        setMilestone(res.data.milestone);
        setMachineType(res.data.machineType);
        setBaselineType(res.data.baselineType);
        setRunStatus(res.data.runStatus);
        setProduct(res.data.product);
        setBaselineBuildRevision(res.data.baselineBuildRevision);
        setTargetBuildRevision(res.data.targetBuildRevision);
        setSku(res.data.sku);
        setDiskType(res.data.diskType);
        setCoreCount(res.data.coreCount);
        setRamSize(res.data.ramSize);
        setMachineName(res.data.machineName);
        setPublicIp(res.data.publicIp);
        setDeviceId(res.data.deviceId);
        setDiskSize(res.data.diskSize);
        //IsExperiment should be evaluated from data
        setRepresentativeRunStatus(
          res.data.representativeRunStatus === "Representative"
        );
        setIsExperiment(false);
      }
    } catch (er) {
      console.log("error", er);
      setNoData(false);
      setInitialLoadingTable(false);
      HandleError(
        er,
        setCorrelationId,
        setShowExceptionPopup,
        props.trackException,
        props.trackWarning,
        { func: setMessage, message: error },
        1
      );
    }
  };

  useEffect(() => {
    fetchIncidentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);

  return (
    <div>
      {InitialLoadingTable ? (
        <Loader
          size={"large"}
          type={"converging-spinner"}
          style={{ alignSelf: "center" }}
        />
      ) : (
        <React.Fragment>
          <RunDetailsTile
            RunNumber={RunId}
            Milestone={Milestone}
            Notes={Notes}
            StartDate={StartDate}
            EndDate={EndDate}
            MachineType={MachineType}
            RunStatus={RunStatus}
            BaselineType={BaselineType}
            Product={Product}
            BaselineBuildRevision={BaselineBuildRevision}
            TargetBuildRevision={TargetBuildRevision}
            Sku={Sku}
            DiskType={DiskType}
            CoreCount={CoreCount}
            RamSize={RamSize}
            IsExperiment={IsExperiment}
            IsLoading={InitialLoadingTable}
            IsRepresentative={representativeRunStatus}
            MachineName={machineName}
            PublicIp={publicIp}
            DeviceId={deviceId}
            DiskSize={diskSize}
          />
        </React.Fragment>
      )}
      <div className="Loading">
        {Loading && InitialLoading === false && (
          <Loader
            size={"large"}
            type={"converging-spinner"}
            style={{ position: "absolute", left: "42%", top: "32%" }}
          />
        )}
      </div>
    </div>
  );
}

export default RunDetails;
