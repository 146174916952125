import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import {
  bytestoMB,
  convertMstoSec,
  convertToPercentage,
  convertToThousand,
} from "./Conversions";
import { saveAs } from "file-saver";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function DiskUtilization({ toggleShowLogs, toggleShowGraphs }) {
  const {
    diskIodata,
    setdiskIodata,
    diskIoCount,
    setdiskIoCount,
    diskReadIo,
    setdiskReadIo,
    diskWriteIo,
    setdiskWriteIo,
    diskTransferdata,
    setdiskTransferdata,
    diskTransferSize,
    setdiskTransferSize,
    diskReadSize,
    setdiskReadSize,
    diskWriteSize,
    setdiskWriteSize,
    diskIoTime,
    setdiskIoTime,
    diskIoPerSecond,
    setdiskIoPerSecond,
    diskServiceTime,
    setdiskServiceTime,
    DiskLogsData,
    setDiskLogsData,
    disklogs,
    setdiskLogs,
    diskPerformancedata,
    setdiskPerformancedata,
    RunId,
  } = useContext(RunSummaryContextNV);

  const cycle0 = disklogs.filter((t) => t.Cycle === "0");
  const cycle1 = disklogs.filter((t) => t.Cycle === "1");
  const cycle2 = disklogs.filter((t) => t.Cycle === "2");
  const logsHeaderRowHeight = 30;

  const [Loading, setLoading] = useState(true);
  const initialDataState = {};
  const [showdiskData, setShowDiskdata] = useState(false);
  const [dataState, setDataState] = React.useState();

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskIodata ? (
              <p className="tilefontsize">{convertToThousand(diskIoCount)}</p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">IO COUNT</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Read:
              {diskIodata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertToThousand(diskReadIo)} (
                  {convertToPercentage(diskReadIo, diskIoCount)})
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Write:
              {diskIodata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertToThousand(diskWriteIo)} (
                  {convertToPercentage(diskWriteIo, diskIoCount)})
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskTransferdata ? (
              <p className="tilefontsize">{bytestoMB(diskTransferSize)}</p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">IO SIZE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Read:
              {diskTransferdata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskReadSize)}
                </span>
              ) : (
                <span className="tilecolorstyle">--</span>
              )}
            </p>

            <p className="tilesubheaderstyle" tabIndex={0}>
              Write:
              {diskTransferdata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskWriteSize)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskPerformancedata ? (
              <p className="tilefontsize">{convertMstoSec(diskIoTime)}</p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">IO TIME</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              IO Per Sec:
              {diskPerformancedata ? (
                <span className="tilecolorstyle"> {diskIoPerSecond}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Service Time:
              {diskPerformancedata ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(diskServiceTime)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  useEffect(() => {
    const fetchDiskUtilizationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetDiskUtilizationDetails(RunId);
        setLoading(false);
        if (Object.keys(res.data).length != 0) {
          setShowDiskdata(true);
          if (res.data.DiskIo == null) {
            setdiskIodata(false);
          } else {
            setdiskIoCount(res.data.DiskIo.DiskIoCount);
            setdiskReadIo(res.data.DiskIo.ReadIoCount);
            setdiskWriteIo(res.data.DiskIo.WriteIoCount);
          }
          if (res.data.DiskTransfer == null) {
            setdiskTransferdata(false);
          } else {
            setdiskTransferSize(res.data.DiskTransfer.TransferSizeBytes);
            setdiskReadSize(res.data.DiskTransfer.ReadSizeBytes);
            setdiskWriteSize(res.data.DiskTransfer.WriteSizeBytes);
          }
          if (res.data.DiskPerformance == null) {
            setdiskPerformancedata(false);
          } else {
            setdiskIoTime(res.data.DiskPerformance.IoTimeMilliseconds);
            setdiskIoPerSecond(res.data.DiskPerformance.IoPerSecond);
            setdiskServiceTime(
              res.data.DiskPerformance.ServiceTimeMilliseconds
            );
          }
          if (res.data.Logs == null) {
            setDiskLogsData(false);
          } else {
            setdiskLogs([...res.data.Logs]);
          }
        } else {
          setShowDiskdata(false);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchDiskUtilizationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Disk Utilization{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }
  return (
    <React.Fragment>
      {showdiskData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight="auto"
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={diskTiles}
              columnWidth={476}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default DiskUtilization;
