function CrossSignComponent() {
    return (
        <span className="crosssign">
            <div className="crosssign_circle"></div>
            <div className="crosssign_stem"></div>
            <div className="crosssign_stem2"></div>
        </span>
    );
}

export default CrossSignComponent;
