import React, { createContext, useState } from "react";
import { FilterOptions } from "../Constants/FilterOptions";
import { useHistory, useLocation } from "react-router-dom";
import { CalcDate } from "../CalcFilterValue";
import { DefaultValue } from "../Constants/DefaultValue";

const FilterContext = createContext();

const FilterContextProvider = (props) => {
  var history = useHistory();
  var location = useLocation();
  const initialParams = new URLSearchParams(
    location.pathname === "/"
      ? location.search.substring(1)
      : history.location.state
      ? history.location.state.ExSumSearch
      : ""
  );

  const GetParam = (name) => {
    var value = initialParams.get(name);
    return value ? value.split(",") : name === 'MilestoneType' ? ['Experiment'] : [];
  };

  const [Show, setShow] = useState(false);
  const [QueryString, setQueryString] = useState(
    location.pathname === "/"
      ? location.search.substring(1)
      : history.location.state
      ? history.location.state.ExSumSearch
      : ""
  );
  const [ShowMore, setShowMore] = useState(1);
  // const [ShowRepairItemPopup, setShowRepairItemPopup] = useState(false)

  const options = FilterOptions(ShowMore);

  const [Product, setProduct] = useState(
    !QueryString ? [] : GetParam("Product")
  );
  const [Release, setRelease] = useState(
    !QueryString ? [] : GetParam("Release")
  );
  const [Milestone, setMilestone] = useState(
    !QueryString ? [] : GetParam("Milestone")
  );
  const [RunStatus, setRunStatus] = useState(
    !QueryString ? [] : GetParam("RunStatus")
  );
  const [BaselineType, setBaselineType] = useState(
    !QueryString ? [] : GetParam("BaselineType")
  );
  const [MachineType, setMachineType] = useState(
    !QueryString ? [] : GetParam("MachineType")
  );
  const [MilestoneType, setMilestoneType] = useState(
    !QueryString ? ['Experiment'] : GetParam("MilestoneType")
  );

  const [fromDate, setFromDate] = useState(
    !QueryString
      ? DefaultValue.FromDate
      : new Date(initialParams.get("startDate"))
  );
  const [toDate, setToDate] = useState(
    !QueryString ? DefaultValue.ToDate : new Date(initialParams.get("endDate"))
  );

  const [Body, setBody] = useState({
    Product: Product,
    Release: Release,
    Milestone: Milestone,
    RunStatus: RunStatus,
    BaselineType: BaselineType,
    MachineType: MachineType,
    MilestoneType: MilestoneType,
    DateRange: CalcDate(fromDate, toDate),
  });

  const [Page_No, setPage_No] = useState(1);
  const [Data, setData] = useState([]);
  const [InitialLoading, setInitialLoading] = useState(true);
  const [ShowExceptionPopup, setShowExceptionPopup] = useState();
  const [CorrelationId, setCorrelationId] = useState("");

  const value = {
    Product,
    setProduct,
    Release,
    setRelease,
    Milestone,
    setMilestone,
    RunStatus,
    setRunStatus,
    BaselineType,
    setBaselineType,
    MachineType,
    setMachineType,
    options,
    ShowMore,
    setShowMore,
    location,
    QueryString,
    setQueryString,
    history,
    Show,
    setShow,
    Body,
    setBody,
    Page_No,
    setPage_No,
    Data,
    setData,
    InitialLoading,
    setInitialLoading,
    ShowExceptionPopup,
    setShowExceptionPopup,
    CorrelationId,
    setCorrelationId,
    // ShowRepairItemPopup,
    // setShowRepairItemPopup,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    MilestoneType,
    setMilestoneType,
  };

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
};

export { FilterContextProvider, FilterContext };
