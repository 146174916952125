import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import {
  bytestoMB,
  convertMstoSec,
  convertToPercentage,
  convertToThousand,
} from "./Conversions";
import { saveAs } from "file-saver";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function DiskUtilizationNV({ toggleShowLogs, toggleShowGraphs }) {
  const {
    diskIodata,
    setdiskIodata,
    diskIoCount,
    setdiskIoCount,
    diskReadIo,
    setdiskReadIo,
    diskWriteIo,
    setdiskWriteIo,
    diskTransferdata,
    setdiskTransferdata,
    diskTransferSize,
    setdiskTransferSize,
    diskReadSize,
    setdiskReadSize,
    diskWriteSize,
    setdiskWriteSize,
    diskIoTime,
    setdiskIoTime,
    diskIoPerSecond,
    setdiskIoPerSecond,
    diskServiceTime,
    setdiskServiceTime,
    DiskLogsData,
    setDiskLogsData,
    disklogs,
    setdiskLogs,
    diskPerformancedata,
    setdiskPerformancedata,
    diskV2logs,
    setdiskV2Logs,
    DiskV2LogsData,
    setDiskV2LogsData,
    diskIodataV2,
    setdiskIodataV2,
    diskUDataV2,
    setdiskUDataV2,
    RunId,
  } = useContext(RunSummaryContextNV);

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  let cycle0 = null;
  let cycle1 = null;
  let cycle2 = null;

  if (diskV2logs && diskV2logs.length) {
    cycle0 = diskV2logs.filter((t) => t.Cycle === "0");
    cycle1 = diskV2logs.filter((t) => t.Cycle === "1");
    cycle2 = diskV2logs.filter((t) => t.Cycle === "2");
  }

  const logsHeaderRowHeight = 30;

  const [Loading, setLoading] = useState(true);
  const initialDataState = {};
  const [showdiskData, setShowDiskdata] = useState(false);
  const [dataState, setDataState] = React.useState();

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskUDataV2 ? (
              <p className="tilefontsize">
                {bytestoMB(diskUDataV2.FileReadSize.WindowsUpdateSizeBytes, 0)}
              </p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">READ</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              System:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileReadSize.SystemSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Defender:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileReadSize.DefenderSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileReadSize.OtherSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskUDataV2 ? (
              <p className="tilefontsize">
                {" "}
                {bytestoMB(diskUDataV2.FileWriteSize.WindowsUpdateSizeBytes)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">WRITE</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              System:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileWriteSize.SystemSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle">--</span>
              )}
            </p>

            <p className="tilesubheaderstyle" tabIndex={0}>
              Defender:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileWriteSize.DefenderSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Other:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {bytestoMB(diskUDataV2.FileWriteSize.OtherSizeBytes)}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {diskUDataV2 ? (
              <p className="tilefontsize">
                {convertMstoSec(diskUDataV2.FilePerformance.IoTimeMilliseconds)}
              </p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">IO TIME</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" tabIndex={0}>
              Read:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(
                    diskUDataV2.FilePerformance.ReadTimeMilliseconds
                  )}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Write:
              {diskUDataV2 ? (
                <span className="tilecolorstyle">
                  {" "}
                  {convertMstoSec(
                    diskUDataV2.FilePerformance.WriteTimeMilliseconds
                  )}
                </span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  useEffect(() => {
    const fetchDiskUtilizationDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetDiskUtilizationDetailsNV(RunId);

        setLoading(false);

        if (Object.keys(res.data).length > 0) {
          setShowDiskdata(true);
          setdiskUDataV2(res.data);
          setdiskV2Logs(res.data.Logs);
        } else {
          setShowDiskdata(false);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchDiskUtilizationDetails();
  }, [RunId]);

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Disk Utilization{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs} />
      </div>
    );
  }

  return (
    <React.Fragment>
      {showdiskData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={3}
              rowHeight="auto"
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={diskTiles}
              columnWidth={480}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default DiskUtilizationNV;
