import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TileLayout } from "@progress/kendo-react-layout";
import "./../Styles/RunDetails.scss";
import * as API from "../Services/ApiService/GetRunDetails";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import { bytestoMB, convertMstoSec, convertToPercentage } from "./Conversions";
import { saveAs } from "file-saver";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";
import LogsGraphsDisplayControl from "./LogsGraphsDisplayControl";

function UpdateTime({ toggleShowLogs, toggleShowGraphs }) {
  const {
    RunId,
    ScanTimeMilliseconds,
    setScanTimeMilliseconds,
    scanOffered,
    setscanOffered,
    DownloadTimeMilliseconds,
    setDownloadTimeMilliseconds,
    DownloadErrorCount,
    setDownloadErrorCount,
    InstallTimeMilliseconds,
    setInstallTimeMilliseconds,
    InstallErrorCount,
    setInstallErrorCount,
    RebootTimeMilliseconds,
    setRebootTimeMilliseconds,
    RebootCount,
    setRebootCount,
    ScanGroupData,
    setScanGroupData,
    DownloadGroupData,
    setDownloadGroupData,
    InstallGroupData,
    setInstallGroupData,
    RebootGroupData,
    setRebootGroupData,
    updatelogs,
    setupdatelogs,
    UpdateLogsData,
    setUpdateLogsData,
  } = useContext(RunSummaryContextNV);

  const cycle0 = updatelogs.filter((t) => t.Cycle === "0");
  const cycle1 = updatelogs.filter((t) => t.Cycle === "1");
  const cycle2 = updatelogs.filter((t) => t.Cycle === "2");

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let diskTiles = [
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {ScanGroupData ? (
              <p className="tilefontsize">
                {convertMstoSec(ScanTimeMilliseconds)}
              </p>
            ) : (
              <p className="tilefontsize">--</p>
            )}
            <p className="tileheaderstyle">SCAN</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Offered:
              {ScanGroupData && scanOffered !== "" && scanOffered !== null ? (
                <span className="tilecolorstyle"> {scanOffered}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {DownloadGroupData ? (
              <p className="tilefontsize">
                {convertMstoSec(DownloadTimeMilliseconds)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">DOWNLOAD</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Errors:
              {DownloadGroupData && DownloadErrorCount !== null ? (
                <span className="tilecolorstyle"> {DownloadErrorCount}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {InstallGroupData ? (
              <p className="tilefontsize">
                {convertMstoSec(InstallTimeMilliseconds)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">INSTALL</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Errors:
              {InstallGroupData && InstallErrorCount !== null ? (
                <span className="tilecolorstyle"> {InstallErrorCount}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      reorderable: false,
      resizable: false,
      item: (
        <div className="tilestyle">
          <div className="first line" tabIndex={0}>
            {RebootGroupData ? (
              <p className="tilefontsize">
                {convertMstoSec(RebootTimeMilliseconds)}
              </p>
            ) : (
              <p className="tilefontsize"> --</p>
            )}
            <p className="tileheaderstyle">REBOOT</p>
          </div>
          <div className="second">
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
            <p className="tilesubheaderstyle" tabIndex={0}>
              Count:
              {RebootGroupData && RebootCount !== null ? (
                <span className="tilecolorstyle"> {RebootCount}</span>
              ) : (
                <span className="tilecolorstyle"> --</span>
              )}
            </p>
            <p className="tilesubheaderstyle" style={{ visibility: "hidden" }}>
              Offered:
              {ScanGroupData ? (
                <span
                  className="tilecolorstyle"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  {scanOffered}
                </span>
              ) : (
                <span className="tilecolorstyle" style={{ color: "white" }}>
                  {" "}
                  --
                </span>
              )}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function getFormattedFileName(logName) {
    let split = logName.split(".");
    let getLastIndex = logName.lastIndexOf(".");
    let extension = split[1];
    let resultantFileName =
      logName.substring(0, 10) +
      "..." +
      logName.substring(getLastIndex - 6, getLastIndex) +
      "." +
      extension;
    return resultantFileName;
  }

  function getUpdateLogsObject(name, annotation) {
    return name + " (" + annotation + ")";
  }

  const [updateTimeData, setUpdateTimeData] = useState(false);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUpdateTimeDetails = async () => {
      setLoading(true);
      try {
        const res = await API.GetUpdateTimeDetails(RunId);
        setLoading(false);
        if (Object.keys(res.data).length != 0) {
          setUpdateTimeData(true);
          if (res.data.Scan == null) {
            setScanGroupData(false);
          } else {
            setScanTimeMilliseconds(res.data.Scan.TimeMilliseconds);
            setscanOffered(res.data.Scan.OfferCount);
          }
          if (res.data.Download == null) {
            setDownloadGroupData(false);
          } else {
            setDownloadTimeMilliseconds(res.data.Download.TimeMilliseconds);
            setDownloadErrorCount(res.data.Download.ErrorCount);
          }
          if (res.data.Install == null) {
            setInstallGroupData(false);
          } else {
            setInstallTimeMilliseconds(res.data.Install.TimeMilliseconds);
            setInstallErrorCount(res.data.Install.ErrorCount);
          }
          if (res.data.Reboot == null) {
            setRebootGroupData(false);
          } else {
            setRebootTimeMilliseconds(res.data.Reboot.TimeMilliseconds);
            setRebootCount(res.data.Reboot.Count);
          }
          if (res.data.Logs == null) {
            setUpdateLogsData(false);
          } else {
            setupdatelogs([...res.data.Logs]);
          }
        } else {
          setUpdateTimeData(false);
        }
      } catch (er) {
        setExceptionText(er.toString());
        setShowException(true);
        setLoading(false);
      }
    };
    fetchUpdateTimeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RunId]);

  function getHeader() {
    return (
      <div>
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Update Time{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
        <LogsGraphsDisplayControl
          toggleShowLogs={toggleShowLogs}
          toggleShowGraphs={toggleShowGraphs}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      {updateTimeData && !showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <TileLayout
              columns={4}
              rowHeight="auto"
              resizable={false}
              gap={{
                rows: 8,
                columns: 8,
              }}
              items={diskTiles}
              columnWidth={358}
            />
          </PanelBarItem>
        </PanelBar>
      )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="diskUtilizationPanel">
          <PanelBarItem expanded={true} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
}
export default UpdateTime;
