import React, { useState, useEffect, useContext } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { getter } from "@progress/kendo-react-common";
import * as API from "../Services/ApiService/GetRunDetails";
import {
  SetRepresentativeRun,
  SetToggleRunsAsAnomalous,
} from "../Services/ApiService/ApiPostCalls";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import { getFormattedDate, runStatus } from "../Common/Utilities";
import { MultiSummary } from "./Summary";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";

import { orderBy } from "@progress/kendo-data-query";
import CrossSignComponent from "../Shared/src/Components/exceptionText/crossSignComponent";
import ExceptionTextComponent from "../Shared/src/Components/exceptionText/exceptionTextComponent";

const initialSort = [
  {
    field: "runId",
    dir: "asc",
  },
];

const ManageMultipleRuns = () => {
  const { RunId, multipleRunData, setMultipleRunData } =
    useContext(RunSummaryContextNV);
  const [LoadingRep, setLoadingRep] = useState(false);
  const [LoadingAnom, setLoadingAnom] = useState(false);
  const [markRepresentativeDisabled, setMarkRepresentativeDisabled] =
    useState(true);
  const [markAnomalousDisabled, setMarkAnomalousDisabled] = useState(true);
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [anomalousSelected, setAnomalousSelected] = useState(true);
  const DATA_ITEM_KEY = "runId";
  const SELECTED_FIELD = "isSelected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({});
  const [representativeRunId, setRepresentativeRunId] = useState("");

  const [showException, setShowException] = useState(false);
  const [exceptionText, setExceptionText] = useState("");

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
      validateForRepresentativeButton(newSelectedState);
      validateForToggleAnomalousButton(newSelectedState);
    },
    [selectedState]
  );

  const getEntity = (id) => {
    let tempData = [];
    if (!multipleRunData.entities) {
      tempData = JSON.parse(sessionStorage.getItem("multipleRunData")).entities;
    } else {
      tempData = multipleRunData.entities;
    }

    let entity = tempData.findIndex((item) => item.runId == id);
    if (entity !== -1) {
      return tempData[entity];
    }
    return null;
  };

  const onClickViewResults = () => {
    for (const id in selectedState) {
      if (selectedState[id] === true) {
        let url = `http://${window.location.host}/run?RunId=${id}`;
        setTimeout(() => window.open(url, "_blank"), 600);
      }
    }
  };

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;

    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    let countTrue = 0;
    let isRepChecked = false;
    for (const id in newSelectedState) {
      if (newSelectedState[id] === true) {
        countTrue += 1;
        if (id === representativeRunId) {
          isRepChecked = true;
          break;
        }
      }
    }

    setTimeout(() => {
      validateForRepresentativeButton(newSelectedState);
      validateForToggleAnomalousButton(newSelectedState);
    }, 200);
    setSelectedState(newSelectedState);
  }, []);

  const validateForRepresentativeButton = function (newSelectedState) {
    let countTrue = 0;
    let isRepChecked = false;
    for (const id in newSelectedState) {
      let entity = getEntity(id);
      if (newSelectedState[id] === true) {
        countTrue += 1;
        if (id === entity.representativeRunId.toString()) {
          isRepChecked = true;
          break;
        }
        if (
          entity !== null &&
          (entity.runStatus.indexOf(runStatus.Success) === -1 ||
            entity.runStatus.indexOf(runStatus.Anomalous) !== -1)
        ) {
          countTrue = 0;
          break;
        }
      }
    }

    let disable = countTrue !== 1 || isRepChecked === true;
    setMarkRepresentativeDisabled(disable);
  };

  const validateForToggleAnomalousButton = function (newSelectedState) {
    let countTrue = 0;
    let isRepChecked = false;
    let anomalousCount = 0;
    let notAnomalousCount = 0;
    for (const id in newSelectedState) {
      let entity = getEntity(id);
      if (newSelectedState[id] === true) {
        countTrue += 1;
        if (id === entity.representativeRunId.toString()) {
          isRepChecked = true;
          break;
        }

        if (
          entity !== null &&
          entity.runStatus.indexOf(runStatus.Anomalous) === -1
        ) {
          notAnomalousCount += 1;
        }
        if (
          entity !== null &&
          entity.runStatus.indexOf(runStatus.Anomalous) !== -1
        ) {
          anomalousCount += 1;
        }
      }
    }

    setAnomalousSelected(anomalousCount > 0 ? false : true);
    setMarkAnomalousDisabled(
      isRepChecked === true ||
        (anomalousCount > 0 && notAnomalousCount > 0) ||
        countTrue < 1
    );
  };
  const rowClick = (e) => {
    console.trace(e.target);
  };

  const fetchGetManageRunsDetails = async () => {
    try {
      const res = await API.GetManageRunsDetails(RunId);
      if (res.data !== null && res.data !== "") {
        res.data.entities.forEach((el, idx) => {
          el.isSelected = false;
          el.startDate = getFormattedDate(el.startDate);
          el.endDate = getFormattedDate(el.endDate);
          el.runStatus = el.freindlyRunStatuses.join(", ");
          if (el.representativeRunId)
            setRepresentativeRunId(el.representativeRunId.toString());
          setMultipleRunData(res.data);
        });
      }
      sessionStorage.setItem("multipleRunData", JSON.stringify(res.data));
    } catch (er) {
      setExceptionText(er.toString());
      setShowException(true);
      console.log(er);
    }
  };

  // Handle accessibility warnings in code
  const fixAccessibilityWarnings = () => {
    document.querySelectorAll("[role='presentation']").forEach((e) => {
      e.removeAttribute("role");
    });

    document
      .querySelectorAll("[id='k-panelbar-item-default-.0']")
      .forEach((e, i) => {
        e.id = "k-panelbar-item-default-." + i;
      });

    document.querySelectorAll(".k-checkbox").forEach((e) => {
      e.setAttribute("title", "Select");
    });

    document.querySelectorAll(".k-checkbox-label").forEach((e) => {
      e.removeAttribute("for");
    });

    document.querySelectorAll(".k-grid").forEach((e) => {
      e.setAttribute("aria-busy", "true");
    });
  };
  // Handle accessibility warnings in code

  useEffect(() => {
    fetchGetManageRunsDetails();
  }, [RunId]);

  window.onclick = function (event) {
    if (
      event.target.childNodes.length > 0 &&
      event.target.childNodes[0].outerText === "Manage Runs"
    ) {
      // Handle accessibility warnings in code
      setTimeout(() => {
        fixAccessibilityWarnings();
      }, 1200);
      // Handle accessibility warnings in code
    }
  };

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };
  const onClickDownloadFile = async () => {
    var response = await API.DownloadRunsExcel(RunId);
  };
  const onMarkRepresentativeClick = async function () {
    setLoadingRep(true);
    const allowed = [true];
    const filtered = Object.fromEntries(
      Object.entries(selectedState).filter(([key, val]) =>
        allowed.includes(val)
      )
    );

    await SetRepresentativeRun(Object.keys(filtered)[0]);
    setLoadingRep(false);
    window.location.reload();
  };

  const onToggleAnomalousClick = async function () {
    setLoadingAnom(true);
    const allowed = [true];
    const filtered = Object.fromEntries(
      Object.entries(selectedState).filter(([key, val]) =>
        allowed.includes(val)
      )
    );

    await SetToggleRunsAsAnomalous(Object.keys(filtered), anomalousSelected);
    setLoadingAnom(false);
    window.location.reload();
  };

  function getHeader() {
    return (
      <div
        style={{
          width: "800px",
          borderRight: "1px black",
        }}
      >
        <span>
          {showException && <CrossSignComponent />}
          {showException && " "}
          Manage Runs{" "}
          <span style={{ fontSize: "12pt", fontWeight: "400" }}>
            {" "}
            by Windows Update
          </span>
        </span>
      </div>
    );
  }

  const [sort, setSort] = React.useState(initialSort);
  return (
    <React.Fragment>
      <style>
        {`col:nth-last-of-type(5){
            width: 2.8%;
        }
        col:nth-last-of-type(4){
          width: 6%;
      }
    
    col:nth-last-of-type(1){
      width: 22%;
  }
        `}
      </style>
      {multipleRunData.entities != null &&
        multipleRunData.entities.length > 0 &&
        Object.keys(multipleRunData).length != 0 &&
        !showException && (
          <PanelBar style={{ margin: "20px" }} className="">
            <PanelBarItem expanded={false} title={getHeader()}>
              {/* <ManageMultipleRunsSummary summaryData={multipleRunData} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "15px",
                }}
              >
                <button
                  className="multirun-action-button"
                  onClick={onClickViewResults}
                >
                  Open in New Tab
                </button>
                <button
                  className="multirun-action-button"
                  onClick={onClickDownloadFile}
                >
                  Export Results
                </button>
                <button
                  className={
                    markRepresentativeDisabled
                      ? "multirun-action-button-disabled"
                      : "multirun-action-button"
                  }
                  disabled={markRepresentativeDisabled}
                  onClick={async () => {
                    await onMarkRepresentativeClick();
                  }}
                >
                  Mark Representative
                  {LoadingRep === true && (
                    <Loader
                      type="pulsing"
                      style={{ color: "#fff" }}
                      size="small"
                    />
                  )}
                </button>

                <button
                  className={
                    markAnomalousDisabled
                      ? "multirun-action-button-disabled"
                      : "multirun-action-button"
                  }
                  disabled={markAnomalousDisabled}
                  onClick={async () => {
                    await onToggleAnomalousClick();
                  }}
                >
                  Toggle Anomalous
                  {LoadingAnom === true && (
                    <Loader
                      type="pulsing"
                      style={{ color: "#fff" }}
                      size="small"
                    />
                  )}
                </button>
              </div>
              <Grid
                data={orderBy(
                  multipleRunData.entities.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })),
                  sort
                )}
                onRowClick={(item) => {
                  let url = `http://${window.location.host}/run?RunId=${item.dataItem.runId}`;
                  window.open(url, "_blank");
                }}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: true,
                  drag: false,
                  cell: false,
                  mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
              >
                <Column
                  field={SELECTED_FIELD}
                  width="50px"
                  headerSelectionValue={
                    multipleRunData.entities.findIndex(
                      (item) => !selectedState[idGetter(item)]
                    ) === -1
                  }
                />
                <Column field="runId" title={"Run"} />
                <Column field="startDate" title="Start Time" />
                <Column field="endDate" title="End Time" />
                <Column field="runStatus" title="Status" />
              </Grid>
              <MultiSummary summaryData={multipleRunData} />
            </PanelBarItem>
          </PanelBar>
        )}
      {showException && (
        <PanelBar style={{ margin: "20px" }} className="">
          <PanelBarItem expanded={false} title={getHeader()}>
            <ExceptionTextComponent exceptionText={exceptionText} />
          </PanelBarItem>
        </PanelBar>
      )}
    </React.Fragment>
  );
};

export default ManageMultipleRuns;
