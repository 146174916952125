import axios, { AxiosResponse } from 'axios';
import { WorkItemFeedBackApiModel, IncidentFeedBackApiModel } from '../../Models/feedbackApiModel';

export async function CreateVsoTask(
    data: WorkItemFeedBackApiModel,
    apiBaseUrl: string,
): Promise<AxiosResponse<string>> {
    return axios({
        url: apiBaseUrl + '/FeedbackButton/CreateTaskFromSuggestion',
        method: 'POST',
        data: data,
    });
}

export async function CreateIcmIncident(
    data: IncidentFeedBackApiModel,
    apiBaseUrl: string,
): Promise<AxiosResponse<string>> {
    return axios({
        url: apiBaseUrl + '/FeedbackButton/CreateIncidientFromIssue',
        method: 'POST',
        data: data,
    });
}
