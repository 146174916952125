function LogsGraphsDisplayControl({ toggleShowLogs, toggleShowGraphs }) {
  return (
    <>
      <span
        class="k-icon k-font-icon k-i-paste-plain-text"
        style={{ marginLeft: "15px" }}
        title="Logs"
        onClick={(event) => {
          event.stopPropagation();
          toggleShowLogs();
        }}
      ></span>
      <span
        class="k-icon k-font-icon k-i-align-bottom-element"
        style={{ marginLeft: "10px" }}
        title="Flame Graphs"
        onClick={(event) => {
          event.stopPropagation();
          toggleShowGraphs();
        }}
      ></span>
    </>
  );
}

export default LogsGraphsDisplayControl;
