/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { feedBackMessages } from '../../Constants/feedback';
import { IFeedBackWindow } from '../../Models/feedbackWindowItem';
import { FeedbackWindowFloater } from './feedbackWindowFloater';

export const FeedbackMenuItem: React.FC<IFeedBackWindow> = ({
    item,
    createWorkItemId,
    createIcmIncidentId,
    setShowFeedWindow,
}) => {
    return (
        <>
            <div className="menu-item-container" tabIndex={0}>
                <a
                    className="topRightElements hoverbackground menu-item-icon-wrapper"
                    onClick={item.handleOpen}
                    tabIndex={12}
                >
                    <img
                        title={feedBackMessages.FeedBackImageTitle}
                        id="btnFeedBack"
                        src={process.env.PUBLIC_URL + '/ari_user-feedback.png'}
                        className="feedback-button"
                        alt="feedback"
                    />
                </a>
                {item.menuItemOpen && (
                    <FeedbackWindowFloater
                        item={item}
                        createWorkItemId={createWorkItemId}
                        createIcmIncidentId={createIcmIncidentId}
                        setShowFeedWindow={setShowFeedWindow}
                    />
                )}
            </div>
        </>
    );
};
