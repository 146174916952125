import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const defaultConfig = {
  wl_apiUrl: "http://waaslab-portal-api-ppe.azurewebsites.net/api/",
  wl_environment: "LOCAL",
  wl_instrumentationKey: "",
  wl_msalConfig: {
    auth: {
      clientId: "",
      authority: "",
      redirectUri: "",
      postLogoutRedirectUri: "/",
    },
    cache: {
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  },
  wl_loginRequest: {
    scopes: ["api://57992bea-c961-40be-b854-43b2d9cc2978/.default"],
  },
  wl_defaultApiToken: {
    scopes: ["api://57992bea-c961-40be-b854-43b2d9cc2978/.default"],
  },
  wl_trackitApiToken: {
    "scopes": ["https://trackit-api-ppe.azurewebsites.net/user_impersonation"]
  },
  wl_shared: {
    tool: {
      toolName: "TrackIt Incident Learning Assistant",
      trackitLink: "https://trackitrelease.azurewebsites.net/"
    },
    feedbackMenu: {
      item: {
        reportName: "incident learning",
        apiBaseUrl: "https://trackit-api-ppe.azurewebsites.net",
        icmPortalLink: "https://ppeportal.microsofticm.com",
        workItemLink:
          "https://microsoft.visualstudio.com/web/wi.aspx?pcguid=cb55739e-4afe-46a3-970f-1b49d8ee7564",
      },
    },
    appSwitcherMenu: {
      items: [
        {
          item: {
            title: "Payload",
            displayName: "Payload",
            iconSrc: "/appswitch-payload.png",
            url: "https://aka.ms/trackitpayload",
          },
        },
        {
          item: {
            title: "TrackIt Release Readiness",
            displayName: "TrackIt Release Readiness",
            iconSrc: "/TRR-Appswitcher.png",
            url: "https://aka.ms/trr",
          },
        },
        {
          item: {
            title: "Binary Search",
            displayName: "Binary Search",
            iconSrc: "/BinarySearchIcon.png",
            url: "https://aka.ms/TrackItBinarySearch",
          },
        },
        {
          item: {
            title: "Blueprint Lookup",
            displayName: "Blueprint Lookup",
            iconSrc: "/appswitch-BlueprintLookup.png",
            url: "https://aka.ms/trackitblueprintlookup",
          },
        },
        {
          item: {
            title: "Product Lifecycle",
            displayName: "Product Lifecycle",
            iconSrc: "/plcIcon.JPG",
            url: "https://aka.ms/plc",
          },
        },
        {
          item: {
            title: "Social Monitoring",
            displayName: "Social Monitoring",
            iconSrc: "/appswitch-trackit-social.png",
            url: "https://aka.ms/trackitsocial",
          },
        },
        {
          item: {
            title: "Package Compare",
            displayName: "Package Compare",
            iconSrc: "/appswitch-packageCompare.svg",
            url: "https://aka.ms/trackitpackagecompare",
          },
        },
        {
          item: {
            title: "Payload KPIs",
            displayName: "Payload KPIs",
            iconSrc: "/appswitch-wsdkpi.png",
            url: "https://aka.ms/payloadkpi",
          },
        },
        {
          item: {
            title: "Missed SLA MSRC Cases",
            displayName: "Missed SLA MSRC Cases",
            iconSrc: "/Security-Bug-icon.png",
            url: "https://aka.ms/TrackItMsrcSla",
          },
        },
        {
          item: {
            title: "Comms Authoring",
            displayName: "Comms Authoring",
            iconSrc: "/CatIcon11.png",
            url: "https://aka.ms/wincomm",
          },
        },
        {
          item: {
            title: "MTP & DTP Reports",
            displayName: "MTP & DTP Reports",
            iconSrc: "/TentReports.png",
            url: "https://aka.ms/TENTReports",
          },
        },
      ],
    },
    links: {
      items: [
        {
          item: {
            title: "Open TrackIt Incident Learning wiki page",
            url: "https://www.osgwiki.com/wiki/WSD_Wiki_-_TrackIt_Incident_Learning_Assistant_(TILA)",
            id: "tilHelpIcon",
            imageSrc: "/trackitInfo.png",
          },
        },
        {
          item: {
            title: "Profile Page",
            url: "#",
            id: "tilProfileIcon",
            imageSrc:
              "https://www.telerik.com/kendo-react-ui-develop/images/kendoka-react.png",
            disabled: true,
          },
        },
        {
          item: {
            title: "Dashboard",
            url: "#",
            id: "tilDashboardIcon",
            imageSrc: "/banner.png",
            disabled: true,
          },
        },
      ],
    },
    textItem: {
      item: {
        text: "Data as of 12/03 08:03 AM",
      },
    },
  },
};

class GlobalConfig {
  config = defaultConfig;
}
export const globalConfig = new GlobalConfig();
export const globalConfigUrl = "config.json";
