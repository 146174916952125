import { globalConfig } from "../../Configuration/config";
import axios from "axios";

export async function filterDetails(filterName) {
  const api = globalConfig.config.wl_apiUrl;
  return axios({
    url: `${api}Filter/${filterName}?api-version=1.0`,
    method: "GET",
    headers: {
      Accept: "text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}
