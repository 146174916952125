import React, { useState } from "react";
import { getAppInsights } from "../Services/AppInsights/TelemetryService";
import TelemetryProvider from "../Services/AppInsights/TelemetryProvider";
import { NotificationContextProvider } from "../Contexts/NotificationContext";
import { globalConfig } from "../Configuration/config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ExecutiveSummaryDashboard from "./ExecutiveSummaryDashboard";
import { MsalAuthenticationTemplate, withMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import DrawerRouterContainer from "./DrawerRouterContainer";
import NotFound from "../Navigation/NotFound";
import { getAccessTokenResponse } from "../Services/ApiService/ApiCall";
import { getMsalInstance } from "..";
import "../app.scss";
import ErrorBoundary from "./ErrorBoundary";
// import { SeverityLevel } from '@microsoft/applicationinsights-web';

const Application = () => {
  const instrumentationKey = globalConfig.config.wl_instrumentationKey;
  let appInsights = null;

  function trackException() {
    const ai = getAppInsights();
    ai.trackException({ error: "error on click", severityLevel: Error });
  }

  // function trackTrace(message) {
  //   const ai = getAppInsights()
  //   ai.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
  // }

  function trackEvent(name, property) {
    const ai = getAppInsights();
    ai.trackEvent({ name: name, properties: property });
  }

  const [token, setToken] = useState("");
  // const [Username, setUsername] = useState("");
  const loginRequest = globalConfig.config.wl_loginRequest;
  const authRequest = {
    ...loginRequest,
  };

  const msalInstance = getMsalInstance();
  const account =
    msalInstance !== undefined ? msalInstance.getActiveAccount() : undefined;

  if (msalInstance !== undefined && account !== undefined && token === "") {
    getAccessTokenResponse(loginRequest).then((response) => {
      if (response !== undefined) {
        setToken(response.accessToken);
        // setUsername(response.account.username);
      }
    });
  }

  // useEffect(() => {
  //   if (Username) trackEvent("User Logged in", {username: Username})
  // }, [Username]);

  return (
    <Router>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <TelemetryProvider
          instrumentationKey={instrumentationKey}
          after={() => {
            appInsights = getAppInsights();
          }}
        >
          <ErrorBoundary
            trackException={trackException}
            trackEvent={trackEvent}
          >
            <NotificationContextProvider>
              <DrawerRouterContainer>
                <Switch>
                  {/* <Route exact = {true} path = "/dashboard" component={Dashboard} /> */}
                  <Route
                    path="/"
                    render={() => <ExecutiveSummaryDashboard authkey={token} />}
                  />
                  <Route path="*" component={NotFound} />
                </Switch>
              </DrawerRouterContainer>
            </NotificationContextProvider>
          </ErrorBoundary>
        </TelemetryProvider>
      </MsalAuthenticationTemplate>
    </Router>
  );
};

export const App = withMsal(Application);
