import React, { useContext, useEffect, useRef } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { FilterContext } from "../Contexts/FilterContext";
import { CalcDate } from "../CalcFilterValue";
import { withRouter } from "react-router-dom";
import { DefaultValue } from "../Constants/DefaultValue";
import { Error } from "@progress/kendo-react-labels";
import $ from "jquery";

const Filter = (props) => {
  const SetQueryStringParam = (name, value) => {
    return value ? `${name}=${value.toString()}` : "";
  };

  const style = {
    width: "370px",
  };

  const {
    Product,
    setProduct,
    Release,
    setRelease,
    Milestone,
    setMilestone,
    RunStatus,
    setRunStatus,
    BaselineType,
    setBaselineType,
    MachineType,
    setMachineType,
    options,
    ShowMore,
    setShowMore,
    location,
    QueryString,
    setQueryString,
    history,
    Show,
    setShow,
    setBody,
    Page_No,
    setPage_No,
    Data,
    setData,
    InitialLoading,
    setInitialLoading,
    ShowExceptionPopup,
    setShowExceptionPopup,
    CorrelationId,
    setCorrelationId,
    // ShowRepairItemPopup,
    // setShowRepairItemPopup,
    SearchText,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    MilestoneType,
    setMilestoneType,
  } = useContext(FilterContext);

  const handleClear = () => {
    setProduct([]);
    setRelease([]);
    setMilestone([]);
    setRunStatus([]);
    setBaselineType([]);
    setMachineType([]);
    setMilestoneType(["Experiment"]);
    setFromDate(DefaultValue.FromDate);
    setToDate(DefaultValue.ToDate);
  };

  const handleApply = () => {
    setData([]);
    setPage_No(1);
    setQueryString(
      `${SetQueryStringParam(
        "Product",
        Product.toString()
      )}&${SetQueryStringParam(
        "Release",
        Release.toString()
      )}&${SetQueryStringParam(
        "Milestone",
        Milestone.length ? Milestone.map((m) => m.id) : [] //Milestone.toString()
      )}&${SetQueryStringParam(
        "RunStatus",
        RunStatus.toString()
      )}&${SetQueryStringParam(
        "BaselineType",
        BaselineType.toString()
      )}&${SetQueryStringParam(
        "MachineType",
        MachineType.toString()
      )}&${SetQueryStringParam(
        "MilestoneType",
        MilestoneType.toString()
      )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
        toDate?.toISOString().split("T")[0]
      }`
    );
    setBody({
      Product: Product,
      Release: Release,
      Milestone: Milestone.length ? Milestone.map((m) => m.id) : [],
      RunStatus: RunStatus,
      BaselineType: BaselineType,
      MachineType: MachineType,
      MilestoneType: MilestoneType,
      dateRange: CalcDate(fromDate, toDate),
    });
    setInitialLoading(true);
    if (props.location.pathname !== "/") history.push("/");
  };

  useEffect(() => {
    // Handle accessibility warnings in code
    $(".k-input-values").attr("title", "FilterValues");
    $(".k-input-values").attr("role", "listitem");
    $(".k-input").removeAttr("role");
    $(".k-input").removeAttr("aria-expanded");
    $(".k-input").removeAttr("aria-owns");

    $(".k-input").removeAttr("aria-valuemin");
    $(".k-input").removeAttr("aria-valuemax");
    $(".k-input").removeAttr("aria-valuetext");
    $(".k-input").removeAttr("aria-valuenow");

    $(".k-chip").attr("role", "group");
    $(".k-chip-solid").attr("role", "group");
    $(".k-chip-solid").removeAttr("aria-checked");
    $(".k-chip").attr("title", "multiMilestone");
    $(".k-select").removeAttr("aria-label");
    $("ul.k-reset").attr("title", "milestone type");
    // Handle accessibility warnings in code
    var params = new URLSearchParams(
      QueryString
        ? QueryString
        : `${SetQueryStringParam(
            "Product",
            Product.toString()
          )}&${SetQueryStringParam(
            "Release",
            Release.toString()
          )}&${SetQueryStringParam(
            "Milestone",
            Milestone.length ? Milestone.map((m) => m.id) : []
          )}&${SetQueryStringParam(
            "RunStatus",
            RunStatus.toString()
          )}&${SetQueryStringParam(
            "BaselineType",
            BaselineType.toString()
          )}&${SetQueryStringParam(
            "MachineType",
            MachineType.toString()
          )}&${SetQueryStringParam(
            "MilestoneType",
            MilestoneType.toString()
          )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
            toDate?.toISOString().split("T")[0]
          }`
    );

    var state = history.location.state;
    state = {
      ...state,
      ExData: Data.length
        ? Data
        : history.location.state
        ? history.location.state.ExData
        : [],
    };
    history.push({
      search: params.toString(),
      state: state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryString]);

  useEffect(() => {
    var params = new URLSearchParams(
      QueryString
        ? QueryString
        : `${SetQueryStringParam(
            "Product",
            Product.toString()
          )}&${SetQueryStringParam(
            "Release",
            Release.toString()
          )}&${SetQueryStringParam(
            "Milestone",
            Milestone.length ? Milestone.map((m) => m.id) : []
          )}&${SetQueryStringParam(
            "RunStatus",
            RunStatus.toString()
          )}&${SetQueryStringParam(
            "BaselineType",
            BaselineType.toString()
          )}&${SetQueryStringParam(
            "MachineType",
            MachineType.toString()
          )}&${SetQueryStringParam(
            "MilestoneType",
            MilestoneType.toString()
          )}&startDate=${fromDate?.toISOString().split("T")[0]}&endDate=${
            toDate?.toISOString().split("T")[0]
          }`
    );
    var state = history.location.state;
    state = {
      ...state,
      ExData: Data.length
        ? Data
        : history.location.state
        ? history.location.state.ExData
        : [],
    };
    history.push({
      search: params.toString(),
      state: state,
    });
  }, [Data]);

  return (
    <div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"product-editor"}
          title={"productDropdown"}
          data={options.Product}
          defaultValue={[]}
          value={Product}
          onChange={(event) => {
            setProduct(event.target.value);
          }}
          style={style}
          label={"Product"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"milestone-editor"}
          title="milestoneDropdown"
          data={options.Milestone}
          defaultValue={[]}
          value={Milestone}
          textField="name"
          dataItemKey="id"
          onChange={(event) => {
            setMilestone(event.target.value);
          }}
          style={style}
          label={"Milestone"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"milestone-type-editor"}
          data={options.MilestoneType}
          value={MilestoneType}
          autoWidth={false}
          fillMode={"solid"}
          onChange={(event) => setMilestoneType(event.target.value)}
          style={style}
          label={"Milestone Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"run-status-editor"}
          data={options.RunStatus}
          defaultValue={[]}
          value={RunStatus}
          fillMode={"solid"}
          onChange={(event) => setRunStatus(event.target.value)}
          style={style}
          label={"Run Status"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"base-line-editor"}
          data={options.BaselineType}
          defaultValue={[]}
          value={BaselineType}
          fillMode={"solid"}
          onChange={(event) => setBaselineType(event.target.value)}
          style={style}
          label={"Baseline Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <MultiSelect
          id={"machine-type-editor"}
          data={options.MachineType}
          defaultValue={[]}
          value={MachineType}
          fillMode={"solid"}
          onChange={(event) => setMachineType(event.target.value)}
          style={style}
          label={"Machine Type"}
        />
      </div>
      <div className={"filter-filters-margin"}>
        <div className="filter-row">
          <div>
            <DatePicker
              label="From date"
              width={130}
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
            />
          </div>
          <div className="check">
            <DatePicker
              label="To date"
              width={130}
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
            />
          </div>
        </div>
      </div>

      {/* <DatePicker value={value} onChange={handleChange} /> */}

      <div className="f-footer">
        <a
          className="filter-clearall"
          title="Clear all fields"
          role="button"
          tabIndex={0}
          onClick={handleClear}
        >
          Clear All
        </a>
        <span className="filter-neutralLine">
          <Button id="submitFilter" type="button" onClick={handleApply}>
            Apply
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Filter;
