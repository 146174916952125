import React from "react";
import Filter from "./Filter";
import { FilterContextProvider } from "../Contexts/FilterContext";
import { withRouter, Switch, Route } from "react-router-dom";
import RunSummaryPage from "./RunSummaryPage";
import ExecutiveSummary from "./ExecutiveSummary";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { RunSummaryContextProvider } from "../Contexts/RunSummaryContextNV";
import { getAppInsights } from "../Services/AppInsights/TelemetryService";
import TelemetryProvider from "../Services/AppInsights/TelemetryProvider";
import { globalConfig } from "../Configuration/config";
import Header from "./Header.jsx";

class ExecutiveSummaryDashboard extends React.Component {
  constructor(props) {
    super(props);
    const isExecutiveSummaryPage = this.props.location.pathname === "/";
    this.state = {
      isCollapse: !isExecutiveSummaryPage,
    };
  }

  toggleFilterSection = (isCollapsed) => {
    this.setState({ isCollapse: isCollapsed });
  };

  handleClick = () => {
    this.setState((e) => ({ expanded: !e.expanded }));
  };

  render() {
    const instrumentationKey = globalConfig.config.wl_instrumentationKey;
    let appInsights = null;

    function trackException(er) {
      const ai = getAppInsights();
      ai.trackException({ error: er, severityLevel: Error });
    }

    const isCollapse = this.state.isCollapse;
    const isExecutiveSummaryPage = this.props.location.pathname === "/";
    return (
      <TelemetryProvider
        instrumentationKey={instrumentationKey}
        after={() => {
          appInsights = getAppInsights();
        }}
      >
        <FilterContextProvider>
          <RunSummaryContextProvider>
            <Header onButtonClick={this.handleClick} />

            <div
              className={"k-card-group"}
              style={{ height: "calc(100vh - 80px)", width: "99.5vw" }}
            >
              <Card
                style={{
                  width: isCollapse ? "1vw" : "",
                  display: isExecutiveSummaryPage ? "" : "none",
                  minWidth: isCollapse ? "35px" : "300px",
                }}
              >
                <CardBody>
                  <CardTitle className={"esd-filters-title"}>
                    {!isCollapse ? "Filters" : ""}
                    <button
                      className="k-button k-button-clear esd-collapse-button"
                      autoFocus
                      role="button"
                      aria-label="collapse"
                      aria-labelledby="collapse-icon"
                      title="collapse"
                      onClick={() => {
                        this.toggleFilterSection(!this.state.isCollapse);
                      }}
                    >
                      <span
                        className={
                          "k-icon esd-collapse-icon " +
                          (isCollapse
                            ? "k-i-arrow-chevron-right"
                            : "k-i-arrow-chevron-left")
                        }
                      />
                    </button>
                    {isCollapse && (
                      <div className={"filter-vertical-text"}>Filters</div>
                    )}
                  </CardTitle>
                  {isExecutiveSummaryPage && (
                    <div style={{ display: isCollapse ? "none" : "" }}>
                      <Filter token={this.props.authkey} />
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card style={{ width: "100%", height: "100%", overflow: "auto" }}>
                <CardBody
                  className={"esd-padding-5"}
                  style={{
                    width: isExecutiveSummaryPage
                      ? isCollapse
                        ? "98vw"
                        : "79vw"
                      : "100%",
                  }}
                >
                  <Switch>
                    <Route exact={true} path="/run">
                      <RunSummaryPage
                        trackException={trackException}
                        toggleFilterSection={this.toggleFilterSection}
                      />{" "}
                    </Route>
                  </Switch>
                  <Route exact={true} path="/">
                    <ExecutiveSummary
                      trackException={trackException}
                      toggleFilterSection={this.toggleFilterSection}
                    />{" "}
                  </Route>
                </CardBody>
              </Card>
            </div>
          </RunSummaryContextProvider>
        </FilterContextProvider>
      </TelemetryProvider>
    );
  }
}

export default withRouter(ExecutiveSummaryDashboard);
