import { IAppSwitcherMenu } from '../../Models/appSwitcherItem';
import { AppSwitcherMenuItem } from './appSwitcherMenuItem';

export const AppSwitcherMenu: React.FC<IAppSwitcherMenu> = ({ items }) => {
    return (
        <div className="appSwitcherBox dropdown-content">
            <div className="arrow-up-background"></div>
            <div className="arrow-up"></div>
            <div>
                <ul>
                    {items.map((menuItem, index) => {
                        return <AppSwitcherMenuItem key={index} item={menuItem.item} />;
                    })}
                </ul>
            </div>
        </div>
    );
};
